import React from 'react';
import { toReadableDate, toReadableTime } from '../utils/Utils';

function TimeStamp({ time, title = 'Date Created' }) {
    return (
        <>
            <span className="p-column-title">{title}</span>
            {toReadableDate(time)} <br />
            {toReadableTime(time)}
        </>
    );
}

export default TimeStamp;
