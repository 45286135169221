import Dashboard from '../pages/Dashboard';
import Distributor from '../pages/administrator/Distributor';
import LookUpValue from '../pages/administrator/LookUpValue';
import Currency from '../pages/administrator/Currencies';
import Manufacturer from '../pages/administrator/Manufacturer';
import ProductsView from '../pages/administrator/ProductsView';
import Shop from '../pages/administrator/ShopsList';
import ShopAttendant from '../pages/administrator/ShopAttendant';
import ShopCapital from '../pages/administrator/ShopCapital';
import ShopOwner from '../pages/administrator/ShopOwner';
import StockEntry from '../pages/administrator/StockEntry';
import Supplier from '../pages/administrator/Supplier';
import User from '../pages/administrator/User';
import * as routPaths from './NavigationRoutes';
import ShopSales from '../pages/administrator/ShopSales';
import StockListing from '../pages/administrator/StockListing';
import Expenses from '../pages/administrator/Expenses';
import FinancialSummary from '../pages/administrator/FinancialSummary.js';
import AdminReports from '../pages/administrator/AdminReports';
import AdminStockLevel from '../pages/administrator/AdminStockLevel.js';
import Permissions from '../pages/administrator/Permissions';
import Role from '../pages/administrator/Role';
import CreditSales from '../pages/shopowner/CreditSales';
import CreditPayments from '../pages/shopowner/CreditPayments';
import ShopClients from '../pages/shopowner/ShopClients';
import ShopOwnerReports from '../pages/shopowner/ShopOwnerReports';
import ShopProduct from '../pages/administrator/StockLevel';

export class AdminNavigationConfig {
    /**
     *
     * @param userDetails
     */
    constructor() {}

    navigationMenu = [
        {
            items: [{ label: 'Dashboard', icon: 'bx bxs-dashboard', to: '/' }]
        },
        {
            items: [
                {
                    label: 'Sales Desk',
                    icon: 'bx bx-receipt',
                    to: routPaths.SHOP_SALES_ROUTE_PATH
                }
            ]
        },
        ,
        {
            items: [{ label: 'Money Transfers ', disabled: true }]
        },
        {
            items: [{ label: 'Mobile Money', icon: 'bx bx-money', to: '/' }]
        },
        {
            items: [{ label: 'Shop Management ', disabled: true }]
        },

        {
            items: [
                {
                    label: 'Reports',
                    icon: 'bx bxs-report',
                    to: '/',
                    items: [
                        { label: 'Sales', icon: 'bx bx-cart', to: routPaths.REPORTS_ROUTE_PATH },
                        { label: 'Stock level', icon: 'bx bx-objects-vertical-bottom', to: routPaths.STOCK_LEVELS_ROUTE_PATH },
                        { label: 'Financial summary', icon: 'bx bx-line-chart', to: routPaths.FINANCIAL_SUMMARY_PATH }
                    ]
                }
            ]
        },
        {
            items: [
                {
                    label: ' Debt mgt',
                    icon: 'bx bxs-book-bookmark',
                    to: '/',
                    items: [
                        {
                            label: 'Debtors',
                            icon: 'bx bx-user',
                            to: routPaths.SHOP_CLIENTS
                        },
                        {
                            label: 'Debt sales',
                            icon: 'bx bxs-bookmark-alt-minus',
                            to: routPaths.CREDIT_SALES_ROUTE_PATH
                        },
                        {
                            label: 'Payments',
                            icon: 'bx bx-money',
                            to: routPaths.CREDIT_PAYMENTS_ROUTE_PATH
                        }
                    ]
                }
            ]
        },
        {
            items: [
                {
                    label: 'Manage stock',
                    icon: 'bx bx-layer',
                    to: '/',
                    items: [
                        { label: 'Stock purchase', icon: 'bx bx-layer-plus', to: routPaths.STOCK_ENTRIES_ROUTE_PATH },
                        { label: 'Stock listing', icon: 'bx bx-list-plus', to: routPaths.STOCK_LISTING_ROUTE_PATH }
                    ]
                }
            ]
        },
        {
            items: [
                {
                    label: 'Shop mgt',
                    icon: 'bx bxs-store',
                    to: '/',
                    items: [
                        { label: 'Owners', icon: 'bx bx-user', to: routPaths.SHOP_OWNERS_ROUTE_PATH },
                        { label: 'Shops', icon: 'bx bx-store', to: routPaths.SHOPS_ROUTE_PATH },
                        { label: 'Attendants', icon: 'bx bxs-user-account', to: routPaths.SHOP_ATTENDANTS_ROUTE_PATH }
                    ]
                }
            ]
        },
        {
            items: [
                {
                    label: 'Finances',
                    icon: 'bx bx-briefcase-alt-2',
                    to: '/',
                    items: [
                        { label: 'Capital', icon: 'bx bx-money', to: routPaths.SHOP_CAPITAL_ROUTE_PATH },
                        { label: 'Expenses', icon: 'bx bx-wallet', to: routPaths.EXPENSES_ROUTE_PATH }
                    ]
                }
            ]
        },

        {
            items: [{ label: 'Manage Supply ', disabled: true }]
        },
        {
            items: [
                {
                    label: 'Product chain',
                    icon: 'bx bx-git-branch',
                    to: '/',
                    items: [
                        { label: 'Manufacturers', icon: 'bx bxs-factory', to: routPaths.MANUFACTURERS_ROUTE_PATH },
                        { label: 'Distributors', icon: 'bx bx-buildings', to: routPaths.DISTRIBUTORS_ROUTE_PATH },
                        { label: 'Suppliers', icon: 'bx bxs-truck', to: routPaths.SUPPLIERS_ROUTE_PATH },
                        { label: 'Products', icon: 'bx bx-purchase-tag-alt', to: routPaths.PRODUCTS_ROUTE_PATH }
                    ]
                }
            ]
        },

        {
            items: [{ label: 'Settings ', disabled: true }]
        },
        {
            items: [
                {
                    label: 'User mgt',
                    icon: ' bx bxs-user-badge',
                    to: '/',
                    items: [
                        { label: 'Users', icon: 'bx bx-user', to: routPaths.USERS_ROUTE_PATH },
                        { label: 'Roles', icon: 'bx bxs-user-detail', to: routPaths.ROLES_ROUTE_PATH },
                        { label: 'Permissions', icon: 'bx bxs-user-check', to: routPaths.PERMISSIONS }
                    ]
                }
            ]
        },
        {
            items: [{ label: 'Lookup values', icon: 'bx bx-file-find', to: routPaths.LOOKUPS_ROUTE_PATH }]
        },

        {
            items: [{ label: 'Currencies', icon: 'bx bx-money', to: routPaths.CURRENCIES_ROUTE_PATH }]
        },

        {
            items: [{ label: 'Logout', icon: 'bx bx-log-out', to: routPaths.LOGIN_ROUTE_PATH }]
        }
    ];

    componentRouters = [
        { path: routPaths.DASHBOARD_ROUTE_PATH, label: 'Dashboard', component: <Dashboard />, exact: true },
        { path: routPaths.DISTRIBUTORS_ROUTE_PATH, label: 'Distributor', component: <Distributor />, exact: true },
        { path: routPaths.HOME_ROUTE_PATH, label: 'Dashboard', component: <Dashboard />, exact: true },
        { path: routPaths.LOOKUPS_ROUTE_PATH, label: 'LookUpValue', component: <LookUpValue />, exact: true },
        { path: routPaths.CURRENCIES_ROUTE_PATH, label: 'Currency', component: <Currency />, exact: true },
        { path: routPaths.MANUFACTURERS_ROUTE_PATH, label: 'Manufacturer', component: <Manufacturer />, exact: true },
        { path: routPaths.PRODUCTS_ROUTE_PATH, label: 'Product', component: <ProductsView />, exact: true },
        { path: routPaths.SHOPS_ROUTE_PATH, label: 'Shop', component: <Shop />, exact: true },
        { path: routPaths.SHOP_ATTENDANTS_ROUTE_PATH, label: 'ShopAttendant', component: <ShopAttendant />, exact: true },
        { path: routPaths.SHOP_CAPITAL_ROUTE_PATH, label: 'ShopCapital', component: <ShopCapital />, exact: true },
        { path: routPaths.SHOP_OWNERS_ROUTE_PATH, label: 'ShopOwner', component: <ShopOwner />, exact: true },
        { path: routPaths.STOCK_ENTRIES_ROUTE_PATH, label: 'StockEntry', component: <StockEntry />, exact: true },
        { path: routPaths.STOCK_LEVELS_ROUTE_PATH, label: 'Stock Level', component: <AdminStockLevel />, exact: true },
        { path: routPaths.SUPPLIERS_ROUTE_PATH, label: 'Supplier', component: <Supplier />, exact: true },
        { path: routPaths.SHOP_SALES_ROUTE_PATH, label: 'Supplier', component: <ShopSales />, exact: true },
        { path: routPaths.STOCK_LISTING_ROUTE_PATH, label: 'Stock listing', component: <StockListing />, exact: true },
        { path: routPaths.USERS_ROUTE_PATH, label: 'User', component: <User />, exact: true },
        { path: routPaths.EXPENSES_ROUTE_PATH, label: 'Expenses', component: <Expenses />, exact: true },
        { path: routPaths.REPORTS_ROUTE_PATH, label: 'Reports', component: <AdminReports />, exact: true },
        { path: routPaths.FINANCIAL_SUMMARY_PATH, label: 'Financial Summary', component: <FinancialSummary />, exact: true },
        { path: routPaths.PERMISSIONS, label: 'Permissions', component: <Permissions />, exact: true },
        { path: routPaths.ROLES_ROUTE_PATH, label: 'Roles', component: <Role />, exact: true },
        { path: routPaths.CREDIT_SALES_ROUTE_PATH, label: 'Credit sales', component: <CreditSales />, exact: true },
        { path: routPaths.CREDIT_PAYMENTS_ROUTE_PATH, label: 'Credit sales', component: <CreditPayments />, exact: true },
        { path: routPaths.SHOP_CLIENTS, label: 'Clients', component: <ShopClients />, exact: true },
        { path: routPaths.SHOP_REPORTS_ROUTE_PATH, label: 'Reports', component: <ShopOwnerReports />, exact: true },
        { path: routPaths.SHOP_STOCK_LEVELS_ROUTE_PATH, label: 'Reports', component: <ShopProduct />, exact: true }
    ];

    getNavigationMenu() {
        return this.navigationMenu;
    }

    getComponentRouters() {
        return this.componentRouters;
    }
}
