import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { BaseApiService } from '../../utils/BaseApiService';
import { useNavigate } from 'react-router-dom';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';
import { formatNumberWithCommas, hasNull, toReadableDateTime } from '../../utils/Utils';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import StatusSummary from '../../components/StatusSummary';

const ShopsList = () => {
    let emptyShop = {
        id: null,
        name: null,
        locationLatitude: null,
        locationLongitude: null,
        registrationNumber: null,
        physicalLocation: null,
        currencyId: null,
        initialCapital: null,
        ownerName: null,
        ownerId: null
    };

    const [shops, setShops] = useState(null);
    const [shopDialog, setShopDialog] = useState(false);
    const [deleteShopDialog, setDeleteShopDialog] = useState(false);
    const [shop, setShop] = useState(emptyShop);
    const [selectedShops, setSelectedShops] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [currencies, setCurrencies] = useState(null);
    const [defaultCurrency, setDefaultCurrency] = useState(null);
    const [edit, setEdit] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [shopOwners, setShopOwners] = useState(null);
    const [selectedShopOwner, setSelectedShopOwner] = useState(null);
    const [loading, setLoading] = useState(false);
    const [shopCategories, setShopCategories] = useState([]);
    const [selectedCategory, setSelectdCategory] = useState(null);

    const dialogMessage = useRef();
    const navigate = useNavigate();
    const toast = useRef(null);
    const dt = useRef(null);

    const isAdmin = UserSessionUtils.getSuperAdmin();
    const fetchShopOwners = async () => {
        let searchParameters = { searchTerm: '', offset: 0, limit: 0 };
        new BaseApiService('/shop/owners')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setShopOwners(response.records);
            })
            .catch((error) => {});
    };

    const fetchCurrencies = async () => {
        let searchParameters = { searchTerm: '', offset: 0, limit: 0 };
        new BaseApiService('/currencies')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setCurrencies(response.records);
            })
            .catch((error) => {});
    };

    const fetchShops = async () => {
        let searchParameters = { searchTerm: '', offset: 0, limit: 0 };

        if (UserSessionUtils.getShopOwner()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }
        new BaseApiService('/shops')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setShops(response.records);
            })
            .catch((error) => {});
    };

    const fetchShopCategories = async () => {
        let searchParameters = { searchTerm: '', offset: 0, limit: 0, commaSeparatedTypeIds: [6] };
        new BaseApiService('/lookups/lookup-values')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setShopCategories(response?.records);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchShopOwners();
        fetchCurrencies();
        fetchShopCategories();
    }, []);

    useEffect(() => {
        fetchShops();
    }, [refresh]);

    const openNew = () => {
        setShop(emptyShop);
        setSubmitted(false);
        setShopDialog(true);
        setEdit(false);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setShopDialog(false);
        clearForm();
    };

    const hideShopDialog = () => {
        setDeleteShopDialog(false);
    };

    const onCurrencyChange = (e) => {
        setDefaultCurrency(e.target.value);
    };

    const clearForm = () => {
        setShop(emptyShop);
        setDefaultCurrency(null);
        setSelectdCategory(null);
        setLoading(false);
    };

    const saveShop = async () => {
        setSubmitted(true);
        const apiUrl = edit && edit === true ? '/shops/' + shop.id : '/shops';

        const payload = {
            name: shop.name,
            locationLatitude: shop.locationLatitude,
            locationLongitude: shop.locationLongitude,
            physicalLocation: shop.physicalLocation,
            registrationNumber: shop.registrationNumber,
            initialCapital: shop.initialCapital,
            currencyId: defaultCurrency?.id,
            categoryId: selectedCategory,
            ownerId: selectedShopOwner === null ? UserSessionUtils.getShopOwnerId() : selectedShopOwner?.id
        };
        if (hasNull(payload) === false) {
            setLoading(true);
            await new BaseApiService(apiUrl)
                .saveRequestWithJsonResponse(payload, edit)
                .then((response) => {
                    setLoading(false);
                    fetchShops();
                    hideDialog();
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Shop Details saved', life: 3000 });
                })
                .catch((error) => {
                    showErrorMessage(dialogMessage, error.message);
                    setLoading(false);
                });
        }
    };

    const editShop = (shop) => {
        setEdit(true);
        console.log(shop);
        setShop({ ...shop });
        setSelectdCategory(shop?.categoryId);
        setSelectedShopOwner(shopOwners.find((obj) => obj.id === shop.ownerId));
        setDefaultCurrency(currencies.find((obj) => obj.id === shop.currencyId));
        setShopDialog(true);
    };

    const confirmDeleteShop = (shop) => {
        setShop(shop);
        setDeleteShopDialog(true);
    };

    const deleteShop = () => {
        new BaseApiService('/shops/' + shop.id)
            .deleteRequestWithJsonResponse()
            .then((response) => {
                let _shops = shops.filter((val) => val.id !== shop.id);
                setShops(_shops);
                setDeleteShopDialog(false);
                setShop(emptyShop);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Shop Deleted', life: 3000 });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _shop = { ...shop };
        _shop[`${name}`] = val;

        setShop(_shop);
    };

    const onShopOwnerChange = (e) => {
        setSelectedShopOwner(e.target.value);
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Shop name</span>
                {rowData.name}
            </>
        );
    };

    const locationBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Shop location</span>
                {rowData.physicalLocation}
            </>
        );
    };

    const registrationNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Registration Number</span>
                {rowData.registrationNumber}
            </>
        );
    };

    const dateCreatedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date Created</span>
                {toReadableDateTime(rowData.dateCreated)}
            </>
        );
    };

    const ownerDetailsBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Owner</span>
                {rowData.ownerName}
            </>
        );
    };

    const createdByFullNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created by</span>
                {rowData.createdByFullName}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex md:flex-row md:align-items-center justify-content-center">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success " onClick={() => editShop(rowData)} />
                {shops?.length > 1 && <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger mt-2" onClick={() => confirmDeleteShop(rowData)} />}
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0">Shops</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setGlobalFilter(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" onClick={fetchShops} />
                </div>
            </span>
            <div className="my-2 ml-auto">
                <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
            </div>
        </div>
    );

    const shopDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="outline-btn" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" loading={loading} className="primary-btn" onClick={saveShop} />
        </>
    );

    const deleteShopDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="outline-btn" onClick={hideShopDialog} />
            <Button label="Yes" icon="pi pi-check" className="primary-btn" onClick={deleteShop} />
        </>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} shops
                </span>
            );
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2">
                    <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Shops" value={formatNumberWithCommas(0)} />
                        <StatusSummary title="Attendants" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                        <StatusSummary title="Capital" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                        <StatusSummary title="Removed Capital" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
                    </div>
                </div>

                <div className="card">
                    <Toast ref={toast} />

                    <DataTable
                        ref={dt}
                        value={shops}
                        selection={selectedShops}
                        onSelectionChange={(e) => setSelectedShops(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        className="datatable-responsive"
                        paginatorTemplate={template2}
                        globalFilter={globalFilter}
                        emptyMessage="No shop found."
                        header={header}
                    >
                        <Column field="name" header="Name" body={nameBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }} />
                        <Column field="physicalLocation" header="Physical location" body={locationBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }} />
                        <Column field="registrationNumber" header="Registration Number" body={registrationNumberBodyTemplate} headerStyle={{ width: '15%', minWidth: '8rem' }} />
                        <Column field="categoryName" header="Category" headerStyle={{ width: '15%', minWidth: '10rem' }} />
                        <Column field="shopOwner" header="Owner" body={ownerDetailsBodyTemplate} headerStyle={{ width: '15%', minWidth: '10rem' }} />
                        <Column field="createdBy" header="Created by" body={createdByFullNameBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }} />
                        <Column field="dateCreated" header="Created on" body={dateCreatedBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }} />
                        {isAdmin && <Column body={actionBodyTemplate} header="Actions" className="text-center" headerStyle={{ textAlign: 'center' }} />}
                    </DataTable>

                    <Dialog visible={shopDialog} style={{ width: '700px' }} header="Shop Details" modal className="p-fluid" footer={shopDialogFooter} onHide={hideDialog}>
                        <Messages ref={dialogMessage} style={{ width: '100%' }} />
                        {isAdmin && (
                            <div className="field" style={{ marginTop: '10px' }}>
                                <label htmlFor="shopOwner">Shop Owner</label>
                                <Dropdown id="shopOwner" value={selectedShopOwner} options={shopOwners} onChange={onShopOwnerChange} optionLabel={(option) => option.firstName + ' ' + option.lastName} filter placeholder="Select a Shop owner" />
                                {submitted && !selectedShopOwner && <small className="p-invalid">Shop owner is required.</small>}
                            </div>
                        )}
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="name">Shop Name</label>
                                <InputText id="name" value={shop.name || ''} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !shop.name })} />
                                {submitted && !shop.name && <small className="p-invalid">Shop name is required.</small>}
                            </div>

                            <div className="field col">
                                <label htmlFor="name">Shop category</label>
                                <Dropdown
                                    //
                                    value={selectedCategory}
                                    options={shopCategories}
                                    onChange={(e) => {
                                        setSelectdCategory(e.value);
                                    }}
                                    optionLabel={'value'}
                                    optionValue="id"
                                    filter
                                    placeholder="Select a category"
                                />
                                {submitted && !selectedCategory && <small className="p-invalid">Shop category is required.</small>}
                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="physicalLocation">Physical address</label>
                                <InputText
                                    id="physicalLocation"
                                    value={shop.physicalLocation || ''}
                                    onChange={(e) => onInputChange(e, 'physicalLocation')}
                                    required
                                    autoFocus
                                    className={classNames({ 'p-invalid': submitted && !shop.physicalLocation })}
                                />
                                {submitted && !shop.physicalLocation && <small className="p-invalid">Physical location name is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="registrationNumber">Registration Number</label>
                                <InputText
                                    id="registrationNumber"
                                    value={shop.registrationNumber || ''}
                                    onChange={(e) => onInputChange(e, 'registrationNumber')}
                                    required
                                    autoFocus
                                    className={classNames({ 'p-invalid': submitted && !shop.registrationNumber })}
                                />
                                {submitted && !shop.registrationNumber && <small className="p-invalid">Registration Number is required.</small>}
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="locationLatitude">Location latitude</label>
                                <InputText
                                    id="locationLatitude"
                                    value={shop.locationLatitude || ''}
                                    onChange={(e) => onInputChange(e, 'locationLatitude')}
                                    required
                                    autoFocus
                                    className={classNames({ 'p-invalid': submitted && !shop.locationLatitude })}
                                />
                                {submitted && !shop.locationLatitude && <small className="p-invalid">Location latitude is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="locationLongitude">Location longitude</label>
                                <InputText
                                    id="locationLongitude"
                                    value={shop.locationLongitude || ''}
                                    onChange={(e) => onInputChange(e, 'locationLongitude')}
                                    required
                                    autoFocus
                                    className={classNames({ 'p-invalid': submitted && !shop.locationLongitude })}
                                />
                                {submitted && !shop.locationLongitude && <small className="p-invalid">Location longitude is required.</small>}
                            </div>
                        </div>
                        
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="locationLatitude">Default Currency</label>
                                <Dropdown
                                    options={currencies}
                                    value={defaultCurrency}
                                    optionLabel="name"
                                    onChange={onCurrencyChange}
                                    placeholder="Select a Currency"
                                    className={classNames({ 'p-invalid': submitted && !defaultCurrency })}
                                    checkmark={true}
                                    highlightOnSelect={false}
                                    filter
                                />
                                {submitted && !shop.locationLatitude && <small className="p-invalid">Default currency is required.</small>}
                            </div>
                            {/* <div className="field col">
                                <label htmlFor="locationLongitude">Additional Currencies</label>
                                <MultiSelect
                                    options={currencies?.filter((obj) => obj.id !== defaultCurrency?.id)}
                                    optionLabel="name"
                                    placeholder="Select a Currency"
                                    className={classNames({ 'p-invalid': submitted && !shop.locationLongitude })}
                                    checkmark={true}
                                    highlightOnSelect={false}
                                />
                            </div> */}
                        </div>
                        <div className="field">
                            <label htmlFor="initialCapital">Initial Capital</label>
                            <InputNumber id="initialCapital" value={shop.initialCapital || ''} onValueChange={(e) => onInputChange(e, 'initialCapital')} required autoFocus className={classNames({ 'p-invalid': submitted && !shop.initialCapital })} />
                            {submitted && !shop.initialCapital && <small className="p-invalid">Initial capital is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteShopDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteShopDialogFooter} onHide={hideShopDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {shop && (
                                <span>
                                    Are you sure you want to delete <b>{shop.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
export default ShopsList;
