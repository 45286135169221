import Distributor from '../pages/administrator/Distributor';
import Shop from '../pages/administrator/ShopsList';
import ShopAttendant from '../pages/administrator/ShopAttendant';
import ShopCapital from '../pages/administrator/ShopCapital';
import ShopOwner from '../pages/administrator/ShopOwner';
import ShopProduct from '../pages/administrator/StockLevel';
import StockEntry from '../pages/administrator/StockEntry';
import Supplier from '../pages/administrator/Supplier';
import * as routPaths from './NavigationRoutes';
import ShopSales from '../pages/administrator/ShopSales';
import StockListing from '../pages/administrator/StockListing';
import ShopOwnerReports from '../pages/shopowner/ShopOwnerReports';
import ShopFinancialSummary from '../pages/shopowner/ShopFinancialSummary';
import ShopOwnerDashboard from '../pages/shopowner/ShopOwnerDashboard';
import ShopDetail from '../pages/shops/ShopDetail';
import CreditSales from '../pages/shopowner/CreditSales';
import CreditPayments from '../pages/shopowner/CreditPayments';
import ShopClients from '../pages/shopowner/ShopClients';
import Expenses from '../pages/administrator/Expenses';

export class ShopOwnerNavigationConfig {
    navigationMenu = [
        {
            items: [{ label: 'Dashboard', icon: 'bx bxs-dashboard', to: '/' }]
        },
        {
            items: [
                {
                    label: 'Sales Desk',
                    icon: 'bx bx-receipt',
                    to: routPaths.SHOP_SALES_ROUTE_PATH
                }
            ]
        },

        {
            items: [{ label: 'Shop Management ', disabled: true }]
        },
        {
            items: [{ label: 'Shops', icon: 'bx bx-store', to: routPaths.SHOPS_ROUTE_PATH }]
        },

        { items: [{ label: 'Attendants', icon: 'bx bxs-user-account', to: routPaths.SHOP_ATTENDANTS_ROUTE_PATH }] },

        { items: [{ label: 'Capital', icon: 'bx bx-money', to: routPaths.SHOP_CAPITAL_ROUTE_PATH }] },

        { items: [{ label: 'Expenses', icon: 'bx bx-wallet', to: routPaths.EXPENSES_ROUTE_PATH }] },
        {
            items: [
                {
                    label: 'Reports',
                    icon: 'bx bxs-report',
                    to: '/',
                    items: [
                        { label: 'Sales', icon: 'bx bx-cart', to: routPaths.REPORTS_ROUTE_PATH },
                        { label: 'Stock level', icon: 'bx bx-objects-vertical-bottom', to: routPaths.STOCK_LEVELS_ROUTE_PATH },
                        { label: 'Financial summary', icon: 'bx bx-line-chart', to: routPaths.FINANCIAL_SUMMARY_PATH }
                    ]
                }
            ]
        },
        {
            items: [
                {
                    label: ' Debts management',
                    icon: 'bx bxs-book-bookmark',
                    to: '/',
                    items: [
                        {
                            label: 'Debtors',
                            icon: 'bx bx-user',
                            to: routPaths.SHOP_CLIENTS
                        },
                        {
                            label: 'Debt sales',
                            icon: 'bx bxs-bookmark-alt-minus',
                            to: routPaths.CREDIT_SALES_ROUTE_PATH
                        },
                        {
                            label: 'Payments',
                            icon: 'bx bx-money',
                            to: routPaths.CREDIT_PAYMENTS_ROUTE_PATH
                        }
                    ]
                }
            ]
        },
        {
            items: [
                {
                    label: 'Manage stock',
                    icon: 'bx bx-layer',
                    to: '/',
                    items: [
                        { label: 'Stock purchase', icon: 'bx bx-layer-plus', to: routPaths.STOCK_ENTRIES_ROUTE_PATH },
                        { label: 'Stock listing', icon: 'bx bx-list-plus', to: routPaths.STOCK_LISTING_ROUTE_PATH }
                    ]
                }
            ]
        },
        { items: [{ label: 'Suppliers', icon: 'bx bxs-truck', to: routPaths.SUPPLIERS_ROUTE_PATH }] },

        {
            items: [{ label: 'Settings ', disabled: true }]
        },
        {
            items: [{ label: 'Logout', icon: 'bx bx-log-out', to: routPaths.LOGIN_ROUTE_PATH }]
        }
    ];

    componentRouters = [
        { path: routPaths.DASHBOARD_ROUTE_PATH, label: 'Dashboard', component: <ShopOwnerDashboard />, exact: true },
        { path: routPaths.DISTRIBUTORS_ROUTE_PATH, label: 'Distributor', component: <Distributor />, exact: true },
        { path: routPaths.HOME_ROUTE_PATH, label: 'Dashboard', component: <ShopOwnerDashboard />, exact: true },
        { path: routPaths.SHOPS_ROUTE_PATH, label: 'Shop', component: <Shop />, exact: true },
        { path: routPaths.SHOP_ATTENDANTS_ROUTE_PATH, label: 'ShopAttendant', component: <ShopAttendant />, exact: true },
        { path: routPaths.SHOP_CAPITAL_ROUTE_PATH, label: 'ShopCapital', component: <ShopCapital />, exact: true },
        { path: routPaths.SHOP_SALES_ROUTE_PATH, label: 'ShopCapital', component: <ShopSales />, exact: true },
        { path: routPaths.EXPENSES_ROUTE_PATH, label: 'Expenses', component: <Expenses />, exact: true },
        { path: routPaths.SHOP_OWNERS_ROUTE_PATH, label: 'ShopOwner', component: <ShopOwner />, exact: true },
        { path: routPaths.STOCK_ENTRIES_ROUTE_PATH, label: 'StockEntry', component: <StockEntry />, exact: true },
        { path: routPaths.STOCK_LEVELS_ROUTE_PATH, label: 'Dashboard', component: <ShopProduct />, exact: true },
        { path: routPaths.SUPPLIERS_ROUTE_PATH, label: 'Supplier', component: <Supplier />, exact: true },
        { path: routPaths.STOCK_LISTING_ROUTE_PATH, label: 'Stock Listing', component: <StockListing />, exact: true },
        { path: routPaths.REPORTS_ROUTE_PATH, label: 'Reports', component: <ShopOwnerReports />, exact: true },
        { path: routPaths.FINANCIAL_SUMMARY_PATH, label: 'Financial Summary', component: <ShopFinancialSummary />, exact: true },
        { path: routPaths.SHOP_DETAILS_ROUTE_PATH, label: 'Shop Details', component: <ShopDetail />, exact: true },
        { path: routPaths.CREDIT_SALES_ROUTE_PATH, label: 'Credit sales', component: <CreditSales />, exact: true },
        { path: routPaths.CREDIT_PAYMENTS_ROUTE_PATH, label: 'Credit sales', component: <CreditPayments />, exact: true },
        { path: routPaths.SHOP_CLIENTS, label: 'Clients', component: <ShopClients />, exact: true }
    ];

    getNavigationMenu() {
        return this.navigationMenu;
    }

    getComponentRouters() {
        return this.componentRouters;
    }
}
