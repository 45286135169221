import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { BaseApiService } from '../../utils/BaseApiService';
import { Dropdown } from 'primereact/dropdown';
import { convertDateFormat, toReadableDate, toReadableTime } from '../../utils/Utils';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import { MAXIMUM_RECORDS_PER_PAGE } from '../../constants/Constants';
import { Paginator } from 'primereact/paginator';
import { formatNumberWithCommas } from '../../utils/Utils';
import DeleteRecordDialog from '../../components/dialogs/DeleteRecordDialog';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import EditSaleDialog from '../../components/dialogs/EditSaleDialog';
import StatusSummary from '../../components/StatusSummary';
import SaleSummaryDialog from '../../components/dialogs/SaleSummary';
import { useLocation } from 'react-router-dom';
import RenderCurrency from '../../components/RenderCurrency';
import { TabView, TabPanel } from 'primereact/tabview';

export default function ShopOwnerReports() {
    const toast = useRef(null);
    const dt = useRef(null);

    const [productDialog, setProductDialog] = useState(false);
    const [shopSales, setShopSales] = useState([]);
    const [selectedSale, setSelectedSale] = useState(null); //the table row
    const [limit, setLimit] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [editSale, setEditSale] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [income, setIncome] = useState(0);
    const [salesValue, setSalesValue] = useState(0);
    const [capital, setCapital] = useState(0);
    const [dates, setDates] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [shops, setShops] = useState([]);
    const [selectedShop, setSelectedShop] = useState(null);

    let offset = 0;
    const { state } = useLocation();
    const calendarRef = useRef(null);

    const fetchSales = async () => {
        calendarRef.current.hide();
        setIsLoading(true);
        let searchParameters = {
            searchTerm: searchTerm,
            offset: offset,
            limit: MAXIMUM_RECORDS_PER_PAGE,
            ...(dates && { startDate: convertDateFormat(dates[0]) }),
            ...(dates && !dates[1] && dates[0] && { endDate: convertDateFormat(dates[0], true) }),
            ...(dates && dates[1] && { endDate: convertDateFormat(dates[1], true) }),
            ...(activeIndex > 0 && { shopId: shops[activeIndex]?.id })
        };

        if (UserSessionUtils.getShopOwnerId()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }

        if (state?.id) {
            searchParameters.shopId = state?.id;
        }
        setTotalItems(0);

        await new BaseApiService('/shop-sales')
            .getRequestWithJsonResponse(searchParameters)
            .then((response) => {
                setShopSales(response.records);
                setTotalRecords(response.totalItems);
                setIsLoading(false);
                let qty = [];
                let cartIncome = [];
                let cartCapital = [];

                response.records.forEach((item) => {
                    let items = item?.lineItems.reduce((a, item) => a + item?.quantity, 0);
                    let cartProfit = item?.lineItems.reduce((a, i) => a + i.totalProfit, 0);
                    let cap = item?.lineItems.reduce((a, i) => a + i.totalPurchaseCost, 0); // cart capital

                    cartIncome.push(Math.round(cartProfit));
                    qty.push(items);
                    cartCapital.push(cap);
                });

                setCapital(cartCapital.reduce((a, b) => a + b, 0));
                setTotalItems(qty.reduce((a, b) => a + b, 0));
                setIncome(cartIncome.reduce((a, b) => a + b, 0));
                setSalesValue(response.records.reduce((a, b) => a + b.totalCost, 0));
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error?.message, life: 3000 });
                setIsLoading(false);
            });
    };

    const fetchShops = async () => {
        let searchParameters = { offset: 0, limit: 0 };

        if (UserSessionUtils.getShopOwner()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }
        await new BaseApiService('/shops')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                if (response?.records?.length > 1) {
                    setShops([{ name: 'All shops', id: UserSessionUtils.getShopOwnerId() }, ...response?.records.reverse()]);
                    return;
                }
                setShops(response.records);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    const onPageChange = (e) => {
        offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
        setFirst(e.first);
        setLimit(MAXIMUM_RECORDS_PER_PAGE);

        fetchSales();
    };

    useEffect(() => {
        if (!UserSessionUtils.getSuperAdmin()) {
            fetchShops();
        }
    }, []);

    useEffect(() => {
        fetchSales();
    }, [activeIndex]);

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0">Shop Sales</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left  ml-5">
                <div className="p-inputgroup flex-1">
                    <Calendar ref={calendarRef} className="w-full" selectionMode="range" readOnlyInput hideOnRangeSelection maxDate={new Date()} value={dates} onChange={(e) => setDates(e.value)} placeholder="Date" dateFormat="dd-M-yy" />
                    <Button icon={!dates ? 'pi pi-calendar' : 'pi pi-times-circle'} className="primary-btn" onClick={() => setDates(null)} />
                </div>
            </span>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" onClick={fetchSales} />
                </div>
            </span>
        </div>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} shop sales
                </span>
            );
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex justify-content-center md:align-items-center ">
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-text p-button-success "
                    onClick={() => {
                        setSelectedSale(rowData);
                        setProductDialog(true);
                    }}
                />

                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-text p-button-success "
                    onClick={() => {
                        setSelectedSale(rowData);
                        setEditSale(true);
                    }}
                />

                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-text p-button-danger "
                    onClick={() => {
                        setSelectedSale(rowData);
                        setDeleteProductDialog(true);
                    }}
                />
            </div>
        );
    };

    const amountBodyTemplate = (rowData) => {
        return (
            <>
                <RenderCurrency value={rowData.totalCost} currencySymbol={rowData?.currency} />
            </>
        );
    };

    const recievedBodyTemplate = (rowData) => {
        return (
            <>
                <RenderCurrency value={rowData.amountPaid} currencySymbol={rowData?.currency} />
            </>
        );
    };
    const balanceBodyTemplate = (rowData) => {
        return (
            <>
                <RenderCurrency value={rowData.balanceGivenOut} currencySymbol={rowData?.currency} />
            </>
        );
    };

    const createdByFullNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created by</span>
                {rowData.createdByFullName} <br />
            </>
        );
    };

    const dateCreatedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Sold on</span>
                {toReadableDate(rowData.soldOnDate)} <br />
                {toReadableTime(rowData.dateCreated)}
            </>
        );
    };

    const itemsBodyTemplete = (rowData) => {
        let items = rowData?.lineItems.reduce((a, b) => a + b.quantity, 0);

        return (
            <>
                <span className="p-column-title">Items</span>
                {formatNumberWithCommas(items)}
            </>
        );
    };

    const Income = (rowData) => {
        const cartProfit = rowData?.lineItems.reduce((a, i) => a + i.totalProfit, 0);

        return (
            <>
                <RenderCurrency value={Math.round(cartProfit)} currencySymbol={rowData?.currency} />
            </>
        );
    };

    const nameBodyTemplete = (rowData) => {
        const { name } = rowData;
        let newName = name.split(',').filter((i) => i.length > 0);
        newName = newName.join(',');

        if (newName.length > 22) {
            newName = newName.slice(0, 30).concat('...');
        }
        return (
            <>
                <span className="p-column-title">Transaction</span>
                {newName} <br />
            </>
        );
    };

    const hideDialog = () => {
        setSelectedSale(null);
        setProductDialog(false);
    };

    const dynamicTabs = () => {
        if (shops?.length > 1) {
            return (
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} >
                    {shops.map((shop, index) => (
                        <TabPanel key={index} header={shop.name} className="h-auto"></TabPanel>
                    ))}
                </TabView>
            );
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2 -mt-3">
                    <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Txns" value={formatNumberWithCommas(totalRecords)} />
                        <StatusSummary title="Sales" value={formatNumberWithCommas(salesValue)} iconName="bx bx-receipt" />
                        <StatusSummary title="Capital" value={formatNumberWithCommas(Math.round(capital))} iconName="pi pi-money-bill" />
                        <StatusSummary title="Income" value={formatNumberWithCommas(income)} iconName="bx bx-wallet" />
                    </div>
                </div>

                <div className="py-2 -mt-2">
                    <Accordion>
                        <AccordionTab className="p-fluid" header="Filter sale">
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label>Shop name</label>
                                    <Dropdown />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Transaction id</label>
                                    <InputNumber />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Items</label>
                                    <Dropdown />
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Recieved</label>
                                    <InputNumber />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Amount</label>
                                    <InputNumber />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Balance</label>
                                    <InputNumber />
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Sold by</label>
                                    <Dropdown />
                                </div>

                                <div className="field col">
                                    <label htmlFor="purchasePrice">Start date</label>
                                    <Calendar />
                                </div>

                                <div className="field col">
                                    <label htmlFor="purchasePrice">End date</label>
                                    <Calendar />
                                </div>
                            </div>
                            <div className="flex justify-content-end">
                                <Button label="Clear" icon="pi pi-times" className="p-button-text " style={{ color: 'black', border: '1px solid black', margin: '5px', width: 'fit-content' }} />
                                <Button label="Filter" icon="pi pi-check" className="p-button-text" style={{ color: '#FFEB3B', border: '1px solid black', backgroundColor: 'black', margin: '5px', width: 'fit-content' }} />
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
                <div className="py-2 -mt-1">
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-12">
                            <div className="card">
                                <Toast ref={toast} />

                                {header}

                                <DataTable header={dynamicTabs} loading={isLoading} ref={dt} value={shopSales} dataKey="id" rows={10} className="datatable-responsive" emptyMessage="No shop sales found." size="small" resizableColumns>
                                    <Column field="shopName" header="Transaction " body={nameBodyTemplete} style={{ width: '10%' }} />

                                    <Column header="Qty" body={itemsBodyTemplete} className="text-center" headerStyle={{ textAlign: 'center' }} />

                                    <Column header="Recieved" body={recievedBodyTemplate} className="text-right" headerStyle={{ textAlign: 'right' }} />
                                    <Column header="Amount" body={amountBodyTemplate} className="text-right" headerStyle={{ textAlign: 'right' }} />
                                    <Column header="Balance" body={balanceBodyTemplate} className="text-right" headerStyle={{ textAlign: 'right' }} />
                                    <Column header="Income" body={Income} className="text-right" headerStyle={{ textAlign: 'right' }} />
                                    <Column field="createdByFullName" body={createdByFullNameBodyTemplate} header="Created by" style={{ paddingLeft: '5rem' }} />
                                    <Column header="Sold on" body={dateCreatedBodyTemplate} style={{ width: '15%' }} />

                                    <Column body={actionBodyTemplate} header="Actions" headerStyle={{ textAlign: 'center' }} style={{ width: '10%' }} />
                                </DataTable>
                                <Paginator template={template2} first={first} rows={MAXIMUM_RECORDS_PER_PAGE} totalRecords={totalRecords} alwaysShow={false} onPageChange={onPageChange} />
                            </div>
                        </div>
                    </div>
                </div>

                <SaleSummaryDialog visible={productDialog} hideDialog={hideDialog} selectedSale={selectedSale} />

                <EditSaleDialog toast={toast} selectedSale={selectedSale} editSale={editSale} setEditSale={setEditSale} />

                <DeleteRecordDialog
                    isLoading={isLoading}
                    selectedRecordName={`Sale record ${selectedSale?.id}`}
                    setDeleteProductDialog={setDeleteProductDialog}
                    deleteProductDialog={deleteProductDialog}
                    setSelectedRecord={setSelectedSale}
                    showForm={true}
                    apiUrl={'/shop-sales/' + selectedSale?.id}
                    setIsLoading={setIsLoading}
                    onComplete={() => {
                        fetchSales();
                        setSelectedSale(null);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Sale record deleted', life: 3000 });
                        setIsLoading(false);
                    }}
                />
            </div>
        </div>
    );
}
