/* GoogleMaps.js */

import React from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { GoogleMap } from './GoogleMap';

export const GMP_API_KY = 'AIzaSyBLzletcXGf4MyXHtFBnQ9AueNc7wlMh4k';
export const GoogleMapsWrapper = ({ children }) => {
    // Ideally we want the apiKey to be fetch from an environment variable
    const apiKey = 'AIzaSyBLzletcXGf4MyXHtFBnQ9AueNc7wlMh4k';

    if (!apiKey) {
        return <div>Cannot display the map: google maps api key missing</div>;
    }

    return <Wrapper apiKey={apiKey}>{children}</Wrapper>;
};
