import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { BaseApiService } from '../../utils/BaseApiService';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { convertDateFormat, formatNumberWithCommas, toReadableDate, toReadableDateTime } from '../../utils/Utils';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import { MAXIMUM_RECORDS_PER_PAGE } from '../../constants/Constants';
import { Paginator } from 'primereact/paginator';
import { TabPanel, TabView } from 'primereact/tabview';
import DeleteRecordDialog from '../../components/dialogs/DeleteRecordDialog';
import StockEntryFileUpload from '../../components/dialogs/StockEntryFileUpload';
import StockEntryForm from '../../components/dialogs/StockEntryForm';
import StatusSummary from '../../components/StatusSummary';
import StockEntryRow from '../../components/row_expansions/StockEntryRow';
import FilterComponent from '../../components/FilterComponent';

const StockEntry = () => {
    const [stockEntries, setStockEntries] = useState([]);
    const [stockEntryDialog, setStockEntryDialog] = useState(false);
    const [deleteStockEntryDialog, setDeleteStockEntryDialog] = useState(false);
    const [selectedStockEntries, setSelectedStockEntries] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [limit, setLimit] = useState(0);
    const [isUploadDialogVisible, setIsUploadDialogVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [shops, setShops] = useState([]);
    const [selectedShop, setSelectedShop] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [endDate, setEndDate] = useState(null);

    let offset = 0;

    const dialogMessage = useRef();
    const toast = useRef(null);
    const dt = useRef(null);
    const isShopOwner = UserSessionUtils.getShopOwner();
    const isShopAttendant = UserSessionUtils.getShopAttendant();

    const fetchStockEntries = async () => {
        setIsLoading(true);
        let searchParameters = {
            offset: offset,
            limit: MAXIMUM_RECORDS_PER_PAGE,
            ...(isShopOwner && { shopOwnerId: UserSessionUtils.getShopOwnerId() }),
            ...(isShopAttendant && { shopId: UserSessionUtils.getShopId() }),
            ...(searchTerm && { searchTerm: searchTerm }),
            ...(selectedShop && { shopId: selectedShop?.id }),
            ...(startDate && { startDate: convertDateFormat(startDate) }),
            ...(endDate && { endDate: convertDateFormat(endDate, true) }),
            ...(activeIndex > 0 && { shopId: shops[activeIndex]?.id })
        };

        new BaseApiService('/stock-entries')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setStockEntries(response.records);
                setTotalRecords(response.totalItems);

                setIsLoading(false);
            })
            .catch((error) => {
                showErrorMessage(dialogMessage, error);
                setIsLoading(false);
            });
    };

    const fetchShops = async () => {
        const searchParameters = { offset: 0, limit: 0, ...(isShopOwner && { shopOwnerId: UserSessionUtils.getShopOwnerId() }) };
        new BaseApiService('/shops')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                if (response?.records?.length > 1) {
                    setShops([{ name: 'All shops', id: UserSessionUtils.getShopOwnerId() }, ...response?.records.reverse()]);
                    return;
                }

                setShops(response.records);
            })
            .catch((error) => {});
    };

    const onPageChange = (e) => {
        offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
        setFirst(e.first);
        setLimit(MAXIMUM_RECORDS_PER_PAGE);

        fetchStockEntries();
    };

    const clearFilterFields = () => {
        setSearchTerm(null);
        setSelectedShop(null);
        setEndDate(null);
        setStartDate(null);
    };

    useEffect(() => {
        fetchShops();
    }, []);

    useEffect(() => {
        fetchStockEntries();
    }, [activeIndex]);

    const openNew = () => {
        setSelectedRecord(null);
        setStockEntryDialog(true);
    };

    const productBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.productName}
                <br />
                <span className="text-sm opacity-80">SN: {rowData.serialNumber}</span>
            </>
        );
    };

    const purchaseQtyBodyTemplate = (rowData) => {
        const { formattedQuantity } = rowData;

        let x = formattedQuantity?.split(' ');
        let qty = Math.round(Number(x[0]));

        return <>{qty + ', ' + x.slice(1).join(' ')}</>;
    };

    const statusBodyTemplate = (rowData) => {
        return <>{rowData?.statusName || '_'}</>;
    };

    const purchasePriceBodyTemplate = (rowData) => {
        return <>{formatNumberWithCommas(rowData.purchasePrice, rowData?.currency)}</>;
    };

    const unitCostBodyTemplate = (rowData) => {
        let price = rowData?.purchasePrice / rowData?.purchasedQuantity;

        return <>{formatNumberWithCommas(Math.floor(price), rowData?.currency)}</>;
    };

    const qtyRemainingBodyTemplate = (rowData) => {
        return <>{rowData?.quantityRemaining || '_'}</>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex justify-content-center align-items-center">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-text p-button-success"
                    onClick={() => {
                        setSelectedRecord(rowData);
                        setStockEntryDialog(true);
                    }}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-text p-button-danger"
                    onClick={() => {
                        setSelectedRecord(rowData);
                        setDeleteStockEntryDialog(true);
                    }}
                />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0">Shop purchase</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" value={searchTerm} onInput={(e) => setSearchTerm(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" onClick={fetchStockEntries} />
                </div>
            </span>
            <div className="my-2 ml-auto flex flex-column md:flex-row md:align-items-center">
                <Button label="Upload" icon="pi pi-upload" className="outline-btn mr-2" onClick={() => setIsUploadDialogVisible(true)} />
                <Button label="Template" icon="pi pi-download" className="d-btn mr-2" />
                <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
            </div>
        </div>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} stock entries
                </span>
            );
        }
    };

    const allowExpansion = (rowData) => {
        return rowData !== null && rowData !== undefined;
    };

    const dynamicTabs = () => {
        if (shops?.length > 1 && !UserSessionUtils.getSuperAdmin()) {
            return (
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className='h-3rem'>
                    {shops.map((shop, index) => (
                        <TabPanel key={index} header={shop.name} className="h-auto"></TabPanel>
                    ))}
                </TabView>
            );
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2">
                    <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Purchases" value={formatNumberWithCommas(totalRecords)} />
                        <StatusSummary title="Amount" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                        <StatusSummary title="Avaiabale" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                        <StatusSummary title="Depleted" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
                    </div>
                </div>

                <FilterComponent onFilter={fetchStockEntries} onClear={clearFilterFields}>
                    <div className="formgrid grid">
                        {!isShopAttendant && shops?.length > 1 && (
                            <div className="field col-4">
                                <label htmlFor="shop">Shop</label>
                                <Dropdown id="shop" value={selectedShop} options={shops} onChange={(e) => setSelectedShop(e.target.value)} optionLabel={'name'} filter filterBy="name" placeholder="Select Shop" />
                            </div>
                        )}

                        <div className="field col-4">
                            <label htmlFor="shop">Start Date</label>
                            <Calendar className="w-full" maxDate={endDate ? endDate : new Date()} value={startDate} onChange={(e) => setStartDate(e.value)} placeholder="Start date" dateFormat="dd-M-yy" />
                        </div>

                        <div className="field col-4">
                            <label htmlFor="shop">End Date</label>
                            <Calendar className="w-full" maxDate={new Date()} value={endDate} minDate={startDate ? startDate : new Date()} onChange={(e) => setEndDate(e.value)} placeholder="End date" dateFormat="dd-M-yy" />
                        </div>
                    </div>
                </FilterComponent>

                <div className="card">
                    <Toast ref={toast} />

                    {header}

                    <DataTable
                        loading={isLoading}
                        ref={dt}
                        value={stockEntries}
                        selection={selectedStockEntries}
                        onSelectionChange={(e) => setSelectedStockEntries(e.value)}
                        dataKey="id"
                        rows={10}
                        className="datatable-responsive"
                        emptyMessage="No shop purchases found."
                        header={dynamicTabs}
                        size="small"
                        resizableColumns
                        onRowToggle={(e) => {
                            setExpandedRows(e.data);
                        }}
                        rowExpansionTemplate={(rowData) => <StockEntryRow rowData={rowData} />}
                        expandedRows={expandedRows}
                    >
                        <Column expander={allowExpansion} style={{ width: '5rem' }} />

                        <Column field="product" header="Product" body={productBodyTemplate} style={{ width: '10%' }} />
                        <Column field="formattedQuantity" header="Package" body={purchaseQtyBodyTemplate} />

                        <Column field="quantityRemaining" header="Qty " className="text-center" headerStyle={{ textAlign: 'center' }} body={qtyRemainingBodyTemplate} />

                        <Column field="purchasePrice" header="Amount" className="text-right" headerStyle={{ textAlign: 'right' }} body={purchasePriceBodyTemplate} />
                        <Column field="unitCost" header="Unit cost" className="text-right" headerStyle={{ textAlign: 'right' }} body={unitCostBodyTemplate} />
                        <Column field="batchNumber" header="Batch no." className="text-center" headerStyle={{ textAlign: 'center' }} />

                        <Column field="statusName" header="Status" className="text-center" headerStyle={{ textAlign: 'center' }} body={statusBodyTemplate} />
                        <Column field="stockedOnDate" header="Stocked on " body={(rowData) => <> {toReadableDate(rowData.stockedOnDate)}</>} className="text-center" headerStyle={{ textAlign: 'center' }} />

                        <Column body={actionBodyTemplate} header="Actions" className="text-center" headerStyle={{ textAlign: 'center' }} />
                    </DataTable>
                    <Paginator template={template2} first={first} rows={MAXIMUM_RECORDS_PER_PAGE} totalRecords={totalRecords} alwaysShow={false} onPageChange={onPageChange} />

                    <StockEntryForm
                        onSave={() => {
                            fetchStockEntries();
                            setSelectedRecord(null);
                            setStockEntryDialog(false);
                            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Stock details saved', life: 3000 });
                        }}
                        selectedRecord={selectedRecord}
                        setStockEntryDialog={setStockEntryDialog}
                        stockEntryDialog={stockEntryDialog}
                        shops={shops}
                    />

                    <DeleteRecordDialog
                        isLoading={isLoading}
                        selectedRecordName={`${selectedRecord?.productName} purchase record?`}
                        setDeleteProductDialog={setDeleteStockEntryDialog}
                        deleteProductDialog={deleteStockEntryDialog}
                        setSelectedRecord={setSelectedRecord}
                        showForm={true}
                        apiUrl={'/stock-entries/' + selectedRecord?.id}
                        setIsLoading={setIsLoading}
                        onComplete={() => {
                            fetchStockEntries();
                            setSelectedRecord(null);
                            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Purchase record deleted', life: 3000 });
                            setIsLoading(false);
                        }}
                    />

                    <StockEntryFileUpload toast={toast} isUploadDialogVisible={isUploadDialogVisible} setIsUploadDialogVisible={setIsUploadDialogVisible} fetchStockEntries={fetchStockEntries} />
                </div>
            </div>
        </div>
    );
};

export default StockEntry;
