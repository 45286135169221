import React, { useState, useEffect, useRef, act } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { TabView, TabPanel } from 'primereact/tabview';

import { BaseApiService } from '../../utils/BaseApiService';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';
import { formatNumberWithCommas, hasNull, toReadableDateTime } from '../../utils/Utils';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import { MAXIMUM_RECORDS_PER_PAGE } from '../../constants/Constants';
import { Calendar } from 'primereact/calendar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Paginator } from 'primereact/paginator';
import StatusSummary from '../../components/StatusSummary';
import { useLocation } from 'react-router-dom';

const ShopProduct = () => {
    const { state } = useLocation();

    const [shopProducts, setShopProducts] = useState([]);
    const [unPackStockDialog, setUnPackStockDialog] = useState(false);
    const [productToUnpack, setProductToUnpack] = useState(null);
    const [limit, setLimit] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [quantityToUnpack, setQuantityToUpack] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [shops, setShops] = useState([]);

    let offset = 0;
    const dialogMessage = useRef();
    const toast = useRef(null);
    const dt = useRef(null);

    const fetchShopProducts = async () => {
        setIsLoading(true);
        let searchParameters = {
            offset: offset,
            limit: MAXIMUM_RECORDS_PER_PAGE,
            ...(searchTerm.trim() !== '' && { searchTerm: searchTerm }),
            ...(activeIndex > 0 && { shopId: shops[activeIndex]?.id })
        };
        if (UserSessionUtils.getShopOwner()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }
        if (UserSessionUtils.getShopAttendant()) {
            searchParameters.shopId = UserSessionUtils.getShopId();
        }

        if (state?.id) {
            searchParameters.shopId = state?.id;
        }

        new BaseApiService('/shop-products')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setShopProducts(response.records);
                setTotalRecords(response.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    const fetchShops = async () => {
        let searchParameters = { offset: 0, limit: 0 };

        if (UserSessionUtils.getShopOwner()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }
        await new BaseApiService('/shops')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                if (response?.records?.length > 1) {
                    setShops([{ name: 'All shops', id: UserSessionUtils.getShopOwnerId() }, ...response?.records.reverse()]);
                    return;
                }
                setShops(response.records);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchShopProducts();
    }, [searchTerm, activeIndex]);

    useEffect(() => {
        if (!UserSessionUtils.getSuperAdmin()) {
            fetchShops();
        }
    }, []);

    const hideDialog = () => {
        setUnPackStockDialog(false);
        setProductToUnpack(null);
        setQuantityToUpack(null);
    };

    const unPackStockRecord = () => {
        const payload = {
            stockEntryId: productToUnpack?.id,
            amountToUnpack: quantityToUnpack
        };

        setIsLoading(true);

        if (hasNull(payload) === false) {
            new BaseApiService('/stock-entries/unpack')
                .postRequest(payload)
                .then((d) => d.json())
                .then(async (response) => {
                    if (response.status === 'Success') {
                        setIsLoading(false);
                        hideDialog();
                    } else {
                        showErrorMessage(dialogMessage, response?.message);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    showErrorMessage(dialogMessage, error?.message);
                    setIsLoading(false);
                });
        }
    };

    const onPageChange = (e) => {
        offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
        setFirst(e.first);
        setLimit(MAXIMUM_RECORDS_PER_PAGE);

        fetchShopProducts();
    };

    const productBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Product</span>
                {rowData.productName}
                <br />
                <span className="text-sm opacity-80">SN: {rowData.serialNumber}</span>
            </>
        );
    };

    const sortProducts = (a, b) => {
        return b?.performanceSummary?.totalQuantitySold - a?.performanceSummary?.totalQuantitySold;
    };

    const packedQtyBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Packed</span>
                {formatNumberWithCommas(Math.floor(rowData?.performanceSummary?.packedQuantity || 0))}
            </>
        );
    };

    const unPackedQtyBodyTemplate = (rowData) => {
        const packed = rowData?.performanceSummary?.packedQuantity || 0;
        const rem = packed - Math.trunc(packed || 0);
        return (
            <>
                <span className="p-column-title">Unpacked</span>
                {formatNumberWithCommas(Math.round(rem * rowData?.packageQuantity))}
            </>
        );
    };

    const qtySoldBodyTemplate = (rowData) => {
        return <>{formatNumberWithCommas(rowData?.performanceSummary?.totalQuantitySold)}</>;
    };

    const remainingStockBodyTemplate = (rowData) => {
        let result = rowData?.performanceSummary?.totalQuantityStocked - rowData?.performanceSummary?.totalQuantitySold;
        if (result === undefined || isNaN(result) || result < 1) {
            result = 0;
        }
        return <>{formatNumberWithCommas(result)}</>;
    };

    const finishedOnBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Finished on</span>
                {toReadableDateTime(rowData.finishedOn)}
            </>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0">Stock levels</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" onClick={fetchShopProducts} />
                </div>
            </span>
        </div>
    );

    const unPackProductDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="outline-btn" onClick={hideDialog} />
            <Button loading={isLoading} label="Unpack" icon="pi pi-check" className="primary-btn" onClick={unPackStockRecord} />
        </>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} stock levels
                </span>
            );
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex justify-content-center align-items-center">
                <Button
                    icon="pi pi-inbox"
                    className="p-button-rounded p-button-text p-button-info"
                    onClick={() => {
                        setProductToUnpack(rowData);
                        setUnPackStockDialog(true);
                    }}
                />
            </div>
        );
    };

    const dynamicTabs = () => {
        if (shops?.length > 1) {
            return (
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className='h-2rem'>
                    {shops.map((shop, index) => (
                        <TabPanel key={index} header={shop.name} className="h-auto"></TabPanel>
                    ))}
                </TabView>
            );
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2">
                    <div className="grid">
                        <StatusSummary title="Items" value={formatNumberWithCommas(totalRecords)} />
                        <StatusSummary title="Stock value" value="_" iconName="pi-map-marker" />
                        <StatusSummary title="In stock" value="_" iconName="pi-inbox" />
                        <StatusSummary title="StockOut" value="_" iconName="pi-inbox" />
                    </div>
                </div>
                <div className="py-2 -mt-3">
                    <Accordion>
                        <AccordionTab className="p-fluid" header="Filter stock">
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasedQuantity">Product name</label>
                                    <InputText />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Category</label>
                                    <Dropdown />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Distributor</label>
                                    <Dropdown />
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Manufacturer</label>
                                    <Dropdown />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Shop</label>
                                    <Dropdown />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Listed by</label>
                                    <Dropdown />
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Listed on</label>
                                    <Calendar />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <Button label="Clear" icon="pi pi-times" className="p-button-text " style={{ color: 'black', border: '1px solid black', margin: '5px', width: 'fit-content' }} />
                                <Button label="Filter" icon="pi pi-check" className="p-button-text" style={{ color: '#FFEB3B', border: '1px solid black', backgroundColor: 'black', margin: '5px', width: 'fit-content' }} />
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
                <div className="card">
                    <Toast ref={toast} />

                    {header}

                    <DataTable
                        loading={isLoading}
                        ref={dt}
                        value={shopProducts.sort(sortProducts)} //to remove duplicates caused by multiple api calls
                        dataKey="id"
                        rows={10}
                        className="datatable-responsive"
                        paginatorTemplate={template2}
                        emptyMessage="No stock levels found."
                        header={dynamicTabs}
                        size="small"
                        resizableColumns
                    >
                        <Column field="productName" header="Product" body={productBodyTemplate} style={{ width: '15%' }} />

                        <Column header="In stock" body={remainingStockBodyTemplate} className="text-center" headerStyle={{ textAlign: 'center' }} />

                        <Column field="packedQty" body={packedQtyBodyTemplate} header="Packed" className="text-center" headerStyle={{ textAlign: 'center' }} />
                        <Column field="unpackedQty" body={unPackedQtyBodyTemplate} header="Unpacked" className="text-center" headerStyle={{ textAlign: 'center' }} />

                        <Column field="soldItems" header="Sold " body={qtySoldBodyTemplate} className="text-center" headerStyle={{ textAlign: 'center' }} />

                        <Column field="finishedOn" header="Stockout on" body={finishedOnBodyTemplate} className="text-center" headerStyle={{ textAlign: 'center' }} />
                        <Column body={actionBodyTemplate} header="Actions" className="text-center" headerStyle={{ textAlign: 'center' }} />
                    </DataTable>
                    <Paginator template={template2} first={first} rows={MAXIMUM_RECORDS_PER_PAGE} totalRecords={totalRecords} alwaysShow={false} onPageChange={onPageChange} />

                    <Dialog visible={unPackStockDialog} style={{ width: '400px' }} header={`Unpack ${productToUnpack?.productName}`} modal className="p-fluid" footer={unPackProductDialogFooter} onHide={hideDialog}>
                        <Messages ref={dialogMessage} style={{ width: '100%' }} />

                        <div className="formgrid grid">
                            <label htmlFor="unPackqty"> Enter quantity to unpack</label>
                            <InputNumber value={quantityToUnpack} onValueChange={(e) => setQuantityToUpack(e.target.value)} autoFocus />
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
export default ShopProduct;
