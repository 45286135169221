import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { BaseApiService } from '../utils/BaseApiService';
import { UserSessionUtils } from '../utils/UserSessionUtils';
import { Toast } from 'primereact/toast';
import 'react-phone-input-2/lib/style.css';
import TermsAndCondition from './TermsAndConditions';
import NamesInput from '../components/OnBoarding/NamesInput';
import BioDataComponent from '../components/OnBoarding/BioData';
import ContactInput from '../components/OnBoarding/ContactInput';
import PasswordInput from '../components/OnBoarding/PasswordInput';
import { convertToServerDate } from '../utils/Utils';
import CongsComponent from '../components/OnBoarding/CongsComponent';
import ErrorComponent from '../components/OnBoarding/ErrorComponent';

export const Login = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [names, setnames] = useState(null);
    const [bioData, setBioData] = useState(null);
    const [contactInfo, setContactInfo] = useState(null);
    const [isTermsVisible, setIsTermsVisible] = useState(false);

    const [visible, setVisible] = useState(false);
    const [isSignup, setIsSignUp] = useState(false);

    const [secNum, setSecNum] = useState(0);
    const main = 0;
    const secOne = 1;
    const secTwo = 2;
    const secThree = 3;
    const secFour = 4;
    const secFive = 5;

    const [errorMessage, setErrorMessage] = useState('');

    const widthAdd = 212.5;

    const toast = useRef(null);

    const handleSession = () => {
        setIsSignUp(!isSignup);
    };

    const LoginComp = () => {
        const [loginUsername, setLoginUsername] = useState('');
        const [loginPassword, setLoginPassword] = useState('');
        const [forgotEmail, setForgotEmail] = useState('');

        const onLogin = () => {
            setLoading(true);

            new BaseApiService('/auth/login')
                .postRequestWithJsonResponse({
                    username: loginUsername,
                    password: loginPassword,
                    rememberMe: checked
                })
                .then((response) => {
                    setLoading(false);
                    UserSessionUtils.setFullSessionObject(response);
                    UserSessionUtils.setUserDetails(response.user);
                    UserSessionUtils.setUserAuthToken(response.accessToken);
                    UserSessionUtils.setUserRefreshToken(response.refreshToken);
                    UserSessionUtils.setUserPermissions(response.user.permissions);
                    UserSessionUtils.setSuperAdmin(response.user.isSuperAdmin);
                    UserSessionUtils.setShopOwner(response.user.isShopOwner);
                    UserSessionUtils.setShopAttendant(response.user.isShopAttendant);
                    UserSessionUtils.setShopOwnerId(response.user.shopOwnerId);
                    UserSessionUtils.setShopId(response.user.attendantShopId);
                    UserSessionUtils.setLoginTime(new Date());
                    UserSessionUtils.setLoggedIn(true);
                    navigate('/', { replace: true });
                })
                .catch((error) => {
                    setLoading(false);

                    toast.current.show({ severity: 'error', summary: 'Bad Credentials', detail: 'Incorrect username or password', life: 3000 });
                });
        };

        const handleForgotPassword = () => {
            setVisible(!visible);
        };

        return (
            <>
                <div className="main-div">
                    {!visible && (
                        <>
                            <div className="login-logoe">
                                {!isSignup && <h1>Welcome!</h1>}
                                <img src="assets/demo/images/blocks/logos/Duqact Logo-12.png" alt="hyper" height="25" className="mb-3" />
                            </div>
                            <div className="login-words-div">
                                <p>Duqact is a cutting-edge AccountingTech solution designed to streamline financial management for shop owners, suppliers, and manufacturers.</p>
                                <p>Our platform offers comprehensive accounting features and marketing insights that are crucial for business growth in today’s competitive market.</p>
                            </div>
                        </>
                    )}
                    {!visible && (
                        <div className="login-form-div">
                            <h1>Welcome!</h1>
                            <div className="input-div">
                                <label>Username</label>
                                <input value={loginUsername} onChange={(e) => setLoginUsername(e.target.value)} type="text" name="usrname" placeholder="johndoe@gmail.com"></input>
                            </div>
                            <div className="input-div">
                                <label>Password</label>
                                <input value={loginPassword} onChange={(e) => setLoginPassword(e.target.value)} type="password" name="password" placeholder="Password"></input>
                            </div>
                            <div>
                                <Button className="loginBut" loading={loading} onClick={onLogin} label="LOGIN" />
                                <div>
                                    {/* <a onClick={handleForgotPassword} >Forgot password?</a> */}
                                    <a onClick={handleSession}>Create Account</a>
                                </div>
                            </div>
                        </div>
                    )}
                    {visible && (
                        <div className="forgot-words-div">
                            <h1>Forgot Password?</h1>
                            <p>No worries, reset it here! Enter the email address you used to register at Duqact, and we'll send you a password reset link ASAP.</p>
                        </div>
                    )}
                    {visible && (
                        <div className="forgot-form-div">
                            <div>
                                <label>Email</label>
                                <input value={forgotEmail} onChange={(e) => setForgotEmail(e.target.value)} type="text" placeholder="johndoe@gmail.com"></input>
                            </div>
                            <div>
                                <Button onClick={handleForgotPassword} className=" j-button-outline cancel">
                                    Cancel
                                </Button>
                                <Button className=" j-button-default send">Send</Button>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    };

    /*  CREATE ACCOUNT LOGIC */

    const handleNegativeProgressBar = () => {
        const progressBar = document.querySelector('.progressBar');
        let newWidth = progressBar.clientWidth - widthAdd;
        progressBar.style.width = `${newWidth}px`;
    };

    const handlePositiveProgressBar = () => {
        const progressBar = document.querySelector('.progressBar');
        let newWidth = progressBar.clientWidth + widthAdd;
        progressBar.style.width = `${newWidth}px`;
    };

    const handleBackButton = () => {
        setSecNum(secNum - 1);
        handleNegativeProgressBar();
    };

    const handleBackButtonError = () => {
        setSecNum(secNum - 2);
    };

    const handleNextButton = () => {
        setSecNum(secNum + 1);
        handlePositiveProgressBar();
    };

    const toBioDataForm = (firstname, lastname) => {
        setnames({ firstname, lastname });
        handleNextButton();
    };

    const toContactsForm = (data) => {
        setBioData(data);
        handleNextButton();
    };

    const toPassWordForm = (data) => {
        setContactInfo(data);
        handleNextButton();
    };

    const saveUserInfo = (passwordData) => {
        setLoading(true);
        const { day, month, year, gender } = bioData;
        let dateOfBirth = new Date(`${year}-${month}-${day}`);

        const userInfo = {
            firstName: names.firstname,
            lastName: names.lastname,
            dateOfBirth: convertToServerDate(dateOfBirth),
            emailAddress: contactInfo.email,
            primaryPhoneNumber: contactInfo.phoneNumber,
            initialPassword: passwordData?.newpassword,
            genderId: gender.id
        };
        new BaseApiService('/auth/register/shop-owner')
            .postRequestWithJsonResponse(userInfo)
            .then((response) => {
                setLoading(false);
                handlePositiveProgressBar();
                setSecNum(secFour);
                setBioData(null);
                setContactInfo(null);
                setnames(null);
            })
            .catch((error) => {
                setErrorMessage(error?.message);
                setSecNum(secFive);
                setLoading(false);
            });
    };

    const handleFinishButton = () => {
        setIsSignUp(false);
        setSecNum(0);
    };

    const handleCancelButton = () => {
        setIsTermsVisible(!isTermsVisible);
    };

    const handleAcceptButton = () => {
        setIsTermsVisible(false);
    };

    const CreateAccountComponent = (
        <div>
            {secNum === main && <NamesInput onCancel={handleSession} onNext={toBioDataForm} names={names} />}
            {secNum === secOne && <BioDataComponent onBackPress={handleBackButton} onNext={toContactsForm} bioData={bioData} />}
            {secNum === secTwo && <ContactInput onBackPress={handleBackButton} onNext={toPassWordForm} contactInfo={contactInfo} />}
            {secNum === secThree && <PasswordInput onBackPress={handleBackButton} onSubmit={saveUserInfo} loading={loading} />}
            {isTermsVisible && <TermsAndCondition OnCancel={handleCancelButton} OnAccept={handleAcceptButton} />}
            {secNum === secFour && <CongsComponent handleFinishButton={handleFinishButton} />}
            {secNum === secFive && <ErrorComponent handleBackButtonError={handleBackButtonError} errorMessage={errorMessage} />}
        </div>
    );

    return (
        <>
            <Toast ref={toast} />
            <main>
                <div className="div-main">
                    <div className={isSignup ? 'nonlog' : 'login-logo'}>
                        {!isSignup && <h1>Welcome!</h1>}
                        <img src="assets/demo/images/blocks/logos/Duqact Logo-12.png" alt="hyper" height="25" className="mb-3" />
                    </div>
                    {!isSignup && <LoginComp className="login-component" />}
                    {isSignup && CreateAccountComponent}
                    {isSignup && <div className="progressBar"></div>}
                    <div className="footerlinks">
                        <a href="">Contact us</a>
                        <a href="">Terms and Policies</a>
                        <p className="logb">
                            Powered by <span>Satesoft</span>
                        </p>
                    </div>
                </div>
                <div>
                    <a href="">Contact us</a>
                    <a href="">Terms and Policies</a>
                    <p className="logb">Powered by Satesoft</p>
                </div>
            </main>
        </>
    );
};
