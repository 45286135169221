import StorageParams from '../constants/StorageParams';

export class UserSessionUtils {
    /**
     * This is used to get the user's bearer token.
     *
     * @returns
     */
    static getBearerToken() {
        return localStorage.getItem(StorageParams.ACCESS_TOKEN);
    }

    /**
     * This is used to get the user's refresh token.
     *
     * @returns
     */
    static getRefreshToken() {
        return localStorage.getItem(StorageParams.REFRESH_TOKEN);
    }
    /**
     * This method is used to clear the localstorage and redirect the user to the login screen
     */
    static clearLocalStorageAndLogout() {
        // remove all
        localStorage.clear();
    }

    /**
     * This method is use to set the user's bearer token.
     *
     * @param bearerToken
     */
    static setUserAuthToken(bearerToken) {
        localStorage.setItem(StorageParams.ACCESS_TOKEN, bearerToken);
    }

    /**
     *
     */
    static setUserPermissions(permissions) {
        localStorage.setItem(StorageParams.PERMISSIONS, permissions);
    }

    /**
     *
     */
    static setSuperAdmin(superAdmin) {
        localStorage.setItem(StorageParams.IS_SUPER_ADMIN, superAdmin);
    }
    /**
     *
     */
    static setShopOwner(shopOwner) {
        localStorage.setItem(StorageParams.IS_SHOP_OWNER, shopOwner);
    }
    /**
     *
     */
    static setShopAttendant(shopAttedant) {
        localStorage.setItem(StorageParams.IS_SHOP_ATTENDANT, shopAttedant);
    }

    /**
     *
     */
    static setShopOwnerId(shopOwnerId) {
        localStorage.setItem(StorageParams.IS_SHOP_OWNER_ID, shopOwnerId);
    }

    static setShopId(shopId) {
        localStorage.setItem(StorageParams.SHOP_ID, shopId);
    }

    /**
     * This method is use to set the user's bearer token.
     *
     * @param bearerToken
     */
    static setUserSettings(settings) {
        localStorage.setItem(StorageParams.USER_SETTINGS, JSON.stringify(settings));
    }

    /**
     * This method is use to set the user's app settings.
     *
     */
    static getUserSettings() {
        const value = localStorage.getItem(StorageParams.USER_SETTINGS);
        return JSON.parse(value);
    }

    static getPermissions() {
        const value = localStorage.getItem(StorageParams.PERMISSIONS);
        return JSON.parse(value);
    }

    static getSuperAdmin() {
        const value = localStorage.getItem(StorageParams.IS_SUPER_ADMIN);
        return JSON.parse(value);
    }

    static getShopOwner() {
        const value = localStorage.getItem(StorageParams.IS_SHOP_OWNER);
        return JSON.parse(value);
    }

    static getShopAttendant() {
        const value = localStorage.getItem(StorageParams.IS_SHOP_ATTENDANT);
        return JSON.parse(value);
    }

    static getShopOwnerId() {
        const value = localStorage.getItem(StorageParams.IS_SHOP_OWNER_ID);
        return JSON.parse(value);
    }

    static getShopId() {
        const value = localStorage.getItem(StorageParams.SHOP_ID);
        return JSON.parse(value);
    }

    /**
     * This method is use to set the user's bearer token.
     *
     * @param bearerToken
     */
    static setFullSessionObject(fullObject) {
        localStorage.setItem(StorageParams.FULL_LOGIN_DETAILS_JSON, JSON.stringify(fullObject));
    }

    /**
     * This method is use to set the user's bearer token.
     *
     * @param bearerToken
     */
    static getFullSessionObject() {
        const value = localStorage.getItem(StorageParams.FULL_LOGIN_DETAILS_JSON);
        return JSON.parse(value);
    }
    /**
     * This method is used to set the user's refresh token.
     *
     * @param refreshToken
     */
    static setUserRefreshToken(refreshToken) {
        localStorage.setItem(StorageParams.REFRESH_TOKEN, refreshToken);
    }

    /**
     * This method is used to save a JSON object containing user details to local storage.
     *
     * @param userDetails
     */
    static setUserDetails(userDetails) {
        localStorage.setItem(StorageParams.USER_DETAILS_JSON, JSON.stringify(userDetails));
    }

    /**
     * This method is used to get a JSON object containing user details
     * @returns
     */
    static getUserDetails() {
        const value = localStorage.getItem(StorageParams.USER_DETAILS_JSON);
        return JSON.parse(value);
    }

    /**
     * This method is used to get user logged in status
     * @returns
     */
    static isLoggedIn() {
        try {
            const loggedIn = localStorage.getItem(StorageParams.IS_LOGGED_IN);

            if (loggedIn && loggedIn === true) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    /**
     * This method is used to set user logged in status
     * @returns
     */
    static setLoggedIn(loggedIn) {
        if (loggedIn) {
            localStorage.setItem(StorageParams.IS_LOGGED_IN, true);
        } else {
            localStorage.setItem(StorageParams.IS_LOGGED_IN, false);
        }
    }

    /**
     * This method is used to get the stored expo device Id.
     */
    static getDeviceId() {
        return localStorage.getItem(StorageParams.EXPO_DEVICE_ID);
    }

    /**
     * This method is used to store the expo device Id.
     */
    static setDeviceId(token) {
        localStorage.setItem(StorageParams.EXPO_DEVICE_ID, token);
    }

    /**
     * This method is used to save a JSON object containing country details.
     *
     * @param country
     */
    static setUserCountry(country) {
        localStorage.setItem(StorageParams.COUNTRY, JSON.stringify(country));
    }

    /**
     * This method is used to get user country details
     * @returns
     */
    static getUserCountry() {
        return localStorage.getItem(StorageParams.COUNTRY);
    }

    /**
     * This method is used to save a JSON object containing language.
     *
     * @param language
     */
    static setUserLanguage(language) {
        localStorage.setItem(StorageParams.LANGUAGE, JSON.stringify(language));
    }

    /**
     * This method is used to get a JSON object containing user country details
     * @returns
     */
    static getUserLanguage() {
        return localStorage.getItem(StorageParams.LANGUAGE);
    }

    /**
     *
     * @param {*} arr
     * this method sets shop ids for a shopOwner with mutople shops
     */
    static setOwnerShopIds(arr) {
        localStorage.setItem('allShopIds', JSON.stringify(arr));
    }

    /**
     * 
     * @returns 
     * this method returns shop ids for a shopOwner with mutople shops
     
     */
    static getOwnerShopIds() {
        return JSON.parse(localStorage.getItem('allShopIds'));
    }

    static async setLoginTime(time) {
        return localStorage.setItem(StorageParams.LOGIN_TIME, time);
    }

    /**
     * This method is used to get the login timestamp
     * @returns
     */
    static getLoginTime() {
        let time = localStorage.getItem(StorageParams.LOGIN_TIME);
        return time;
    }

    static async setCurrencies(list) {
        return localStorage.setItem(StorageParams.CURRENCIES, JSON.stringify(list));
    }

    static async getCurrencies() {
        const list = localStorage.getItem(StorageParams.CURRENCIES);
        return list ? JSON.parse(list) : [];
    }

    static async getShopCurrency(currencyId) {
        const list = await this.getCurrencies();
        const shopCurrency = list?.find((cur) => cur?.id === currencyId);
        return shopCurrency?.referenceCode;
    }
}
