import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { BaseApiService } from '../../utils/BaseApiService';
import { Dropdown } from 'primereact/dropdown';
import { convertDateFormat, toReadableDate, toReadableTime } from '../../utils/Utils';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import { MAXIMUM_RECORDS_PER_PAGE } from '../../constants/Constants';
import { Paginator } from 'primereact/paginator';
import { formatNumberWithCommas } from '../../utils/Utils';
import { RecieptPrintDialog } from '../RecieptPrintout';
import { Calendar } from 'primereact/calendar';
import SaleSummaryDialog from '../../components/dialogs/SaleSummary';
import PaymentModeForm from '../../components/creditsales/PaymentModeForm';
import { useNavigate } from 'react-router-dom';
import { CREDIT_SALES_ROUTE_PATH } from '../../utils/NavigationRoutes';
import RenderCurrency from '../../components/RenderCurrency';

export default function ShopSales() {
    const toast = useRef(null);
    const dt = useRef(null);
    const navigate = useNavigate();

    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitted2, setSubmitted2] = useState(false);
    const [shopSales, setShopSales] = useState([]);
    const [selectedShop, setSelectedShop] = useState(null);
    const [shops, setShops] = useState([]);

    const [recievedAmount, setRecievedAmount] = useState(0);
    const [quantity, setQuantity] = useState(null);
    const [unitCost, setUnitCost] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedSale, setSelectedSale] = useState(null); //the table row
    const [saleUnits, setSaleUnits] = useState([]);
    const [selectedSaleUnit, setSelectedSaleUnit] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [errors, setErrors] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState(null);

    const [limit, setLimit] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [enterQty, setEnterQty] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [showPaymentDialog, setShowPaymentDialog] = useState(false);

    const [showPrintDialog, setShowPrintDialog] = useState(false);
    const [saleToPrintId, setSaleToPrintId] = useState(0);

    const [dates, setDates] = useState(null);

    let offset = 0;
    const isShopAttendant = UserSessionUtils.getShopAttendant();

    const openPrintDialog = (id) => {
        setSaleToPrintId(id);
        setShowPrintDialog(true);
    };

    const fetchProducts = async () => {
        let searchParameters = {
            offset: 0,
            limit: 20,
            ...(searchTerm.trim() !== '' && { searchTerm: searchTerm }),
            ...(selectedShop && { shopId: selectedShop?.id }),
            ...(isShopAttendant && { shopId: UserSessionUtils.getShopId() })
        };

        if (isShopAttendant || selectedShop) {
            new BaseApiService('/shop-products')
                .getRequestWithJsonResponse(searchParameters)
                .then(async (response) => {
                    setProducts(
                        response.records?.filter((item) => {
                            const remainingStock = item?.performanceSummary?.totalQuantityStocked - item?.performanceSummary?.totalQuantitySold;

                            return remainingStock >= 1;
                        })
                    );
                    setDisabled(false);
                })
                .catch((error) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: error?.message, life: 3000 });
                    setDisabled(false);
                });
        }
    };

    const saveSale = () => {
        setLoading(true);
        setSubmitted(true);
        let isValidSubmision;
        if (isShopAttendant) {
            isValidSubmision = recievedAmount >= totalAmount && cartItems.length > 0;
        } else {
            const isvalidAmount = recievedAmount >= totalAmount && cartItems.length > 0;
            isValidSubmision = isvalidAmount && selectedShop;
        }

        !isValidSubmision && setLoading(false);

        if (isValidSubmision) {
            setShowPaymentDialog(true);
            setLoading(false);
        }
    };
    const fetchSales = async () => {
        setIsLoading(true);

        let searchParameters = {
            offset: offset,
            limit: MAXIMUM_RECORDS_PER_PAGE,
            ...(dates && { startDate: convertDateFormat(dates[0]) }),
            ...(dates && !dates[1] && dates[0] && { endDate: convertDateFormat(dates[0], true) }),
            ...(dates && dates[1] && { endDate: convertDateFormat(dates[1], true) })
        };
        if (UserSessionUtils.getShopOwnerId()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }

        if (UserSessionUtils.getShopAttendant()) {
            searchParameters.shopId = UserSessionUtils.getShopId();
        }
        new BaseApiService('/shop-sales')
            .getRequestWithJsonResponse(searchParameters)
            .then((response) => {
                setShopSales(response.records);
                setTotalRecords(response.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error?.message, life: 3000 });
                setIsLoading(false);
            });
    };

    const fetchShops = async () => {
        let searchParameters = { offset: 0, limit: 0 };

        if (UserSessionUtils.getShopOwnerId()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }

        if (!isShopAttendant) {
            new BaseApiService('/shops')
                .getRequestWithJsonResponse(searchParameters)
                .then(async (response) => {
                    setShops(response.records);
                    if (response.records.length === 1) {
                        setSelectedShop(response.records[0]);
                    }
                })
                .catch((error) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: error?.message, life: 3000 });
                });
        }
    };

    const handleSaleUnitChange = (unit) => {
        setSelectedSaleUnit(unit);
        setUnitCost(unit?.unitPrice);
    };

    const handleProductChange = (e) => {
        const { multipleSaleUnits, saleUnitName, salesPrice } = e.value;

        let defUnit = { productSaleUnitName: saleUnitName, unitPrice: salesPrice };
        if (multipleSaleUnits) {
            setSaleUnits([defUnit, ...multipleSaleUnits]);
        } else {
            setSaleUnits([{ ...defUnit }]);
            setSelectedSaleUnit(defUnit);
            setUnitCost(salesPrice);
        }
        setSubmitted(false);
        setSelectedItem(e.value);

        setSelectedCurrency(e?.value?.currency);

        setEnterQty(true);
    };

    const handleQty = () => {
        setSubmitted2(true);
        let itemUnitCost = unitCost;
        let cost = itemUnitCost * quantity;
        const isWhole = selectedSaleUnit?.id === undefined;

        const productIndex = cartItems.findIndex((item) => {
            if (isWhole) {
                return item?.productName === selectedItem?.productName;
            } else {
                return item?.saleUnitId === selectedSaleUnit?.id;
            }
        });

        const isValidCost = unitCost >= selectedSaleUnit?.unitPrice;

        if (isValidCost === false) {
            setErrors((prev) => {
                return { ...prev, costError: `Unit cost should be greater than ${selectedItem?.salesPrice}` };
            });
        }

        if (isValidCost && Number(quantity) > 0) {
            if (productIndex !== -1) {
                //if it already exists, update quantity and total cost
                let prevQty = cartItems[productIndex].quantity;
                let prevTotalCost = cartItems[productIndex].cost;

                cartItems[productIndex].quantity = Number(quantity) + prevQty;
                cartItems[productIndex].cost = prevTotalCost + cost;
            } else {
                let unitName = selectedSaleUnit?.id !== undefined ? ' - ' + selectedSaleUnit?.productSaleUnitName : '';

                cartItems.push({
                    id: selectedItem.id,
                    productName: selectedItem?.productName + unitName,
                    shopProductId: selectedItem.id,
                    quantity: quantity,
                    cost: cost,
                    unitCost: itemUnitCost,
                    saleUnitId: selectedSaleUnit?.id || null
                });
            }
            setSubmitted2(false);
            setTotalAmount(totalAmount + cost);
            setQuantity(null);
            setUnitCost(null);
            setSelectedSaleUnit(null);
            hideQtyDialog();
        }
    };

    const removeSelection = (data) => {
        const upDatedList = cartItems.filter((item) => data.productName !== item.productName);
        setTotalAmount(totalAmount - data?.cost);
        setCartItems(upDatedList);
    };

    const onPageChange = (e) => {
        offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
        setFirst(e.first);
        setLimit(MAXIMUM_RECORDS_PER_PAGE);

        fetchSales();
    };
    const clearEverything = () => {
        setRecievedAmount(0);
        setSelectedItem(null);
        setQuantity(null);
        setSelectedSale(null);
        setTotalAmount(0);
        setCartItems([]);
        setSubmitted(false);
        setSelectedSale(null);
        setSelectedSaleUnit(null);
        setSelectedCurrency(null);
    };
    useEffect(() => {
        fetchProducts();
    }, [searchTerm, selectedShop]);

    useEffect(() => {
        fetchSales();
        fetchShops();
    }, []);

    useEffect(() => {
        clearEverything();
    }, [selectedShop]);

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center gap-4">
            <h5 className="m-0">Shop Sales</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <div className="p-inputgroup flex-1">
                    <Calendar className="w-full" selectionMode="range" readOnlyInput hideOnRangeSelection maxDate={new Date()} value={dates} onChange={(e) => setDates(e.value)} placeholder="Date" dateFormat="dd-M-yy" />
                    <Button icon={!dates ? 'pi pi-calendar' : 'pi pi-times-circle'} className="primary-btn" onClick={() => setDates(null)} />
                </div>
            </span>

            <Button label="Search" icon="pi pi-search" className="primary-btn" onClick={fetchSales} />
        </div>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} shop sales
                </span>
            );
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex justify-content-center md:align-items-center ">
                <Button icon="pi pi-print" className="p-button-rounded p-button-text p-button-info mt-1" onClick={() => openPrintDialog(rowData.id)} />

                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-text p-button-success mt-1"
                    onClick={() => {
                        setSelectedSale(rowData);
                        setProductDialog(true);
                    }}
                />
            </div>
        );
    };

    const recievedBodyTemplate = (rowData) => {
        return (
            <>
                <RenderCurrency value={rowData.amountPaid} currencySymbol={rowData?.currency} />
            </>
        );
    };

    const balanceBodyTemplate = (rowData) => {
        return (
            <>
                <RenderCurrency value={rowData.balanceGivenOut} currencySymbol={rowData?.currency} />
            </>
        );
    };

    const amountBodyTemplate = (rowData) => {
        return (
            <>
                <RenderCurrency value={rowData.totalCost} currencySymbol={rowData?.currency} />
            </>
        );
    };

    const createdByFullNameBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.createdByFullName} <br />
                <span className="text-sm opacity-80"> {rowData.shopName}</span>
            </>
        );
    };

    const dateCreatedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"></span>
                {toReadableDate(rowData.soldOnDate)} <br />
                {toReadableTime(rowData.dateCreated)}
            </>
        );
    };

    const shopNameBodyTemplate = (rowData) => {
        const { name } = rowData;
        let newName = name.split(',')?.filter((i) => i.length > 0);
        newName = newName?.join(',');

        if (newName.length > 22) {
            newName = newName?.slice(0, 30).concat('...');
        }
        return (
            <>
                <span className="p-column-title"></span>
                {newName} <br />
                <span className="text-sm opacity-80"> {rowData?.serialNumber}</span>
            </>
        );
    };

    const hideDialog = () => {
        setSelectedSale(null);
        setProductDialog(false);
    };

    const hidePaymentDialog = () => {
        setShowPaymentDialog(false);
    };

    const hideQtyDialog = () => {
        setEnterQty(false);
        setSelectedItem(null);
        setQuantity(null);
        setSubmitted2(false);
        setErrors(null);
        setSelectedSaleUnit(null);
        setSaleUnits([]);
    };

    const QtyDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="outline-btn" onClick={hideQtyDialog} />
            <Button label="Confirm" icon="pi pi-check" className="primary-btn" onClick={handleQty} />
        </>
    );

    const selectionsActionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex justify-content-end align-items-end">
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger " onClick={() => removeSelection(rowData)} />
            </div>
        );
    };
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2 -mt-3">
                    <div className="grid reverse-grid">
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="card">
                                <Toast ref={toast} />

                                <DataTable loading={isLoading} ref={dt} value={shopSales} dataKey="id" rows={10} className="datatable-responsive" emptyMessage="No shop sales found." header={header} size="small" resizableColumns>
                                    <Column field="serialNumber" header="Transaction" body={shopNameBodyTemplate} className="text-left" style={{ width: '20%' }} />
                                    <Column header="Recieved" className="text-center" body={recievedBodyTemplate} />

                                    <Column header="Amount" className="text-center" body={amountBodyTemplate} />

                                    <Column header="Balance" className="text-center" body={balanceBodyTemplate} />

                                    <Column field="createdByFullName" body={createdByFullNameBodyTemplate} header="Created by" />
                                    <Column field="dateCreated" header="Sold on" body={dateCreatedBodyTemplate} className="text-left" />
                                    <Column body={actionBodyTemplate} header="Actions" className="text-center" />
                                </DataTable>
                                <Paginator template={template2} first={first} rows={MAXIMUM_RECORDS_PER_PAGE} totalRecords={totalRecords} alwaysShow={true} onPageChange={onPageChange} />
                            </div>
                        </div>

                        <div className="col-12 md:col-12 lg:col-4">
                            <div className="">
                                <div className=" mb-2 card">
                                    {!isShopAttendant && (
                                        <>
                                            <label htmlFor="product">Select Shop</label>
                                            <Dropdown
                                                style={{ width: '100%' }}
                                                value={selectedShop}
                                                options={shops}
                                                optionLabel={(product) => `${product.name} `}
                                                onChange={(e) => {
                                                    setSelectedShop(e.value);
                                                }}
                                                placeholder="Select shop"
                                                filter
                                                className={classNames({ 'p-invalid': submitted && !selectedShop })}
                                                filterInputAutoFocus
                                            />
                                        </>
                                    )}

                                    <div className="mt-2 ">
                                        <label htmlFor="product">Select Product</label>

                                        <Dropdown
                                            disabled={disabled}
                                            style={{ width: '100%' }}
                                            value={selectedItem}
                                            options={products}
                                            optionLabel={(product) => `${product.productName} `}
                                            onChange={handleProductChange}
                                            placeholder="Select item"
                                            filter
                                            filterInputAutoFocus
                                            onFilter={(e) => setSearchTerm(e.filter)}
                                            className={classNames({ 'p-invalid': submitted && !cartItems.length > 0 })}
                                        />
                                    </div>
                                </div>

                                <div className="mb-3 card">
                                    <DataTable value={cartItems} className="datatable-responsive col-12" size="small" emptyMessage=" " style={{ minHeight: '200px' }}>
                                        <Column field="productName" header="Item" className="text-left" style={{ width: '55%' }} />
                                        <Column field="unitCost" header="Price" body={(data) => formatNumberWithCommas(data?.unitCost)} className="text-center" />
                                        <Column field="quantity" header="Qty" className="text-center" />
                                        <Column field="cost" header="Amount" body={(data) => formatNumberWithCommas(data?.cost)} headerStyle={{ textAlign: 'right' }} className="text-right" />
                                        <Column body={selectionsActionBodyTemplate} className="text-center" />
                                    </DataTable>
                                    <div className="px-2 mt-2">
                                        <div className="formgrid grid h-auto mt-2 border-1 border-round border-400 align-items-center">
                                            <div className="col">
                                                <label htmlFor="qnty">Recieved amount</label>
                                            </div>

                                            <div className=" col">
                                                <InputNumber value={recievedAmount} inputClassName=" border-none text-right font-semibold" onValueChange={(e) => setRecievedAmount(e.value)} required />
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mt-2">
                                            <span className="">Sold</span>
                                            <span className="text-sm font-normal px-2">
                                                {selectedCurrency} <span className="text-lg font-semibold ">{formatNumberWithCommas(totalAmount)}</span>
                                            </span>
                                        </div>
                                        <div className="flex justify-content-between mt-2">
                                            <span>Balance</span>
                                            <span className="text-sm font-normal px-2">
                                                {selectedCurrency} <span className="text-lg font-semibold ">{formatNumberWithCommas(recievedAmount - totalAmount)}</span>
                                            </span>
                                        </div>
                                        <div className=""> {submitted && totalAmount > recievedAmount && <small className="p-error">{`Amount should be greater than   ${selectedCurrency + totalAmount}`}</small>}</div>
                                    </div>
                                </div>

                                <div className=" flex justify-content-between gap-3">
                                    <div className={`bg-white p-2 flex flex-column align-items-center justify-content-center border-round w-full`}>
                                        <i className={`pi pi-credit-card text-4xl`}></i>
                                        <span>Card</span>
                                    </div>

                                    <div className={`bg-white p-2 flex flex-column align-items-center justify-content-center border-round w-full`}>
                                        <i className={`pi pi-mobile text-4xl`}></i>
                                        <span>Mobile</span>
                                    </div>

                                    <div className={`bg-white p-2 flex flex-column align-items-center justify-content-center border-round w-full`}>
                                        <i className={`pi pi-comments text-4xl`}></i>
                                        <span>Fap</span>
                                    </div>
                                    <div onClick={() => (!isShopAttendant ? navigate(CREDIT_SALES_ROUTE_PATH) : true)} className={`btn bg-white p-2 flex flex-column align-items-center justify-content-center border-round w-full`}>
                                        <i className={`pi pi-money-bill text-4xl`}></i>
                                        <span>Debt</span>
                                    </div>

                                    <div onClick={clearEverything} className={`p-button p-2 flex flex-column align-items-center justify-content-center border-round w-full`}>
                                        <i className={`pi pi-sync text-4xl`}></i>
                                        <span>Clear</span>
                                    </div>
                                </div>

                                <div className="mt-3 ">
                                    <Button className="w-full font-semibold primary-btn h-3rem" label="Confirm Purchase" onClick={saveSale} loading={loading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <PaymentModeForm
                    cart={{
                        cartItems,
                        recievedAmount,
                        totalAmount,
                        balanceGivenOut: recievedAmount - totalAmount
                    }}
                    selectedShop={selectedShop}
                    showPaymentDialog={showPaymentDialog}
                    hidePaymentDialog={hidePaymentDialog}
                    toast={toast}
                    refreshTable={() => {
                        clearEverything();
                        fetchSales();
                    }}
                    selectedCurrency={selectedCurrency}
                />

                <SaleSummaryDialog visible={productDialog} hideDialog={hideDialog} selectedSale={selectedSale} />

                <Dialog visible={enterQty} style={{ width: '500px' }} className="p-fluid" header={`Successfull `} modal footer={QtyDialogFooter} onHide={hideQtyDialog}>
                    <div className="">
                        <label className="title">{`${selectedItem?.productName} has been selected`}</label>
                        {!selectedSaleUnit && (
                            <div className="field">
                                <label>Select sale unit</label>
                                <div className="flex flex-wrap gap-3 ">
                                    {saleUnits.map((item) => {
                                        const isSelected = selectedSaleUnit?.productSaleUnitName === item?.productSaleUnitName;

                                        return (
                                            <Button
                                                className={classNames({ 'outline-btn p-button-rounded': !isSelected, 'p-button-rounded': isSelected })}
                                                key={item.productSaleUnitName}
                                                onClick={() => handleSaleUnitChange(item)}
                                                label={item.productSaleUnitName}
                                                style={{ width: 'fit-content' }}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                        {selectedSaleUnit && (
                            <>
                                <div className="field mt-3">
                                    <label htmlFor="qty">Quantity</label>
                                    <InputNumber className={classNames({ 'p-invalid': submitted2 && quantity < 1 })} id="qty" value={quantity} onValueChange={(e) => setQuantity(e.value)} required autoFocus />
                                    {submitted2 === true && !quantity && <small className="p-error">Quantity is required.</small>}
                                </div>

                                <div className="field ">
                                    <label>Price {selectedItem?.currency && `(${selectedItem?.currency})`}</label>
                                    <InputNumber className={classNames({ 'p-invalid': submitted2 && errors?.costError })} id="qty" value={unitCost} onValueChange={(e) => setUnitCost(e.value)} required />
                                    {submitted2 === true && errors?.costError && <small className="p-error">{errors?.costError}</small>}
                                </div>
                            </>
                        )}
                    </div>
                </Dialog>

                {showPrintDialog && <RecieptPrintDialog saleId={saleToPrintId} toggleFn={setShowPrintDialog} visible={showPrintDialog} reloadFn={setShowPrintDialog} />}
            </div>
        </div>
    );
}
