import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import PhoneInput from 'react-phone-input-2';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

import { BaseApiService } from '../../utils/BaseApiService';
import { useNavigate } from 'react-router-dom';
import StorageParams from '../../constants/StorageParams';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';
import { formatNumberWithCommas, toReadableDateTime, toReadableTime } from '../../utils/Utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Paginator } from 'primereact/paginator';
import { MAXIMUM_RECORDS_PER_PAGE } from '../../constants/Constants';
import StatusSummary from '../../components/StatusSummary';

const Distributor = () => {
    let initialDistributor = {
        serialNumber: null,
        phoneNumber: null,
        alternativePhoneNumber: null,
        firstName: null,
        lastName: null,
        otherName: null,
        fullName: null,
        companyOrBusinessName: null,
        email: null,
        remarks: null,
        totalSuppliedShops: 0
    };

    const [distributors, setDistributors] = useState(null);
    const [distributorDialog, setDistributorDialog] = useState(false);
    const [deleteDistributorDialog, setDeleteDistributorDialog] = useState(false);
    const [deleteDistributorsDialog, setDeleteDistributorsDialog] = useState(false);
    const [distributor, setDistributor] = useState(initialDistributor);
    const [selectedDistributors, setSelectedDistributors] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [edit, setEdit] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [suppliers, setSuppliers] = useState(null);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [limit, setLimit] = useState(0);

    let offset = 0;
    const dialogMessage = useRef();
    const navigate = useNavigate();
    const toast = useRef(null);
    const dt = useRef(null);

    const fetchDistributors = async () => {
        let searchParameters = { searchTerm: searchTerm, offset: offset, limit: MAXIMUM_RECORDS_PER_PAGE };
        setIsLoading(true);
        new BaseApiService('/distributors')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setDistributors(response.records);
                setTotalRecords(response.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    const fetchSuppliers = async () => {
        let searchParameters = { offset: 0, limit: 0 };
        new BaseApiService('/suppliers')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setSuppliers(response.records);
            })
            .catch((error) => {});
    };

    const onPageChange = (e) => {
        offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
        setFirst(e.first);
        setLimit(MAXIMUM_RECORDS_PER_PAGE);

        fetchDistributors();
    };
    useEffect(() => {
        fetchDistributors();
        fetchSuppliers();
    }, [searchTerm]);

    const openNew = () => {
        setDistributor(initialDistributor);
        setSubmitted(false);
        setDistributorDialog(true);
        setEdit(false);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setDistributorDialog(false);
    };

    const hideDistributorDialog = () => {
        setDeleteDistributorDialog(false);
    };

    const hideDistributorsDialog = () => {
        setDeleteDistributorsDialog(false);
    };

    const saveDistributor = () => {
        setSubmitted(true);

        const apiUrl = edit && edit === true ? '/distributors/' + distributor.id : '/distributors';

        new BaseApiService(apiUrl)
            .saveRequestWithJsonResponse(
                {
                    firstName: distributor.firstName,
                    lastName: distributor.lastName,
                    otherName: distributor.otherName,
                    phoneNumber: distributor.phoneNumber,
                    alternativePhoneNumber: distributor.alternativePhoneNumber,
                    email: distributor.email,
                    companyOrBusinessName: distributor.companyOrBusinessName,
                    remarks: distributor.remarks
                },
                edit
            )
            .then((response) => {
                if (edit && edit === true) {
                    setRefresh(true);
                } else {
                    let _distributors = [...distributors];

                    let _distributor = { ...response };

                    _distributors.push(_distributor);

                    setDistributors(_distributors);
                }
                setDistributorDialog(false);
                setDistributor(initialDistributor);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Distributor Details saved', life: 3000 });
            })
            .catch((error) => {
                showErrorMessage(dialogMessage, error.message);
            });
    };

    const editDistributor = (distributor) => {
        setEdit(true);

        setDistributor({ ...distributor });
        setDistributorDialog(true);
    };

    const confirmDeleteSupplier = (distributor) => {
        setDistributor(distributor);
        setDeleteDistributorDialog(true);
    };

    const deleteSupplier = () => {
        new BaseApiService('/distributors/' + distributor.id)
            .deleteRequestWithJsonResponse()
            .then((response) => {
                let _distributors = distributors.filter((val) => val.id !== distributor.id);
                setDistributors(_distributors);
                setDeleteDistributorDialog(false);
                setDistributor(initialDistributor);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Distributor Deleted', life: 3000 });
            })
            .catch((error) => {});
    };

    const confirmDeleteSelected = () => {
        setDeleteDistributorsDialog(true);
    };

    const deleteSuppliers = () => {
        let _distributors = distributors.filter((val) => !selectedDistributors.includes(val));
        setDistributors(_distributors);
        setDeleteDistributorsDialog(false);
        setSelectedDistributors(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Distributors Deleted', life: 3000 });
    };

    const onInputChange = (e, name) => {
        if (name == 'phoneNumber' || name == 'alternativePhoneNumber') {
            const val = e || '';
            let _distributor = { ...distributor };
            _distributor[`${name}`] = val;
            setDistributor(_distributor);
        } else {
            const val = (e.target && e.target.value) || '';
            let _distributor = { ...distributor };
            _distributor[`${name}`] = val;

            setDistributor(_distributor);
        }
    };

    const serialNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Serial number</span>
                {rowData.serialNumber}
            </>
        );
    };

    const contactPersonBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Contact person</span>
                {rowData.fullName}
            </>
        );
    };

    const businessNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Business name</span>
                {rowData.companyOrBusinessName} <br />
                <span className="text-sm opacity-80">SN: {rowData.serialNumber}</span>
            </>
        );
    };

    const phoneBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Phone</span>
                {rowData.phoneNumber}
            </>
        );
    };

    const createdByFullNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created by</span>
                {rowData.createdByFullName}
            </>
        );
    };

    const dateCreatedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date Created</span>
                {toReadableDateTime(rowData.dateCreated)}
            </>
        );
    };

    const totalSuppliedShopsBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Supplied shops</span>
                <Button
                    label={checkNumericAttribute(rowData.totalSuppliedShops) + ''}
                    style={{ color: '#495057' }}
                    className="p-button-link numlink"
                    // onClick={() =>
                    //     navigate('/administrator/shops', {
                    //         state: {
                    //             // ownerId: rowData.id,
                    //             // ownerName: rowData.firstName + ' ' + rowData.lastName
                    //         }
                    //     })
                    // }
                />
            </>
        );
    };

    function checkNumericAttribute(value) {
        if (value === null || typeof value === 'undefined') {
            return 0;
        } else {
            return value;
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex md:flex-row md:align-items-center">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success mr-2" onClick={() => editDistributor(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger mt-2" onClick={() => confirmDeleteSupplier(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0">Distributors</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" onClick={fetchDistributors} />
                </div>
            </span>
            <div className="my-2 ml-auto">
                <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
                <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} style={selectedDistributors && selectedDistributors.length > 1 ? { display: 'inline-flex' } : { display: 'none' }} />
            </div>
        </div>
    );

    const supplierDialogFoooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="outline-btn" style={{ color: 'black', border: '1px solid black' }} onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="primary-btn" style={{ color: '#FFEB3B', border: '1px solid black', backgroundColor: 'black' }} onClick={saveDistributor} />
        </>
    );
    const deleteManufacturerDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="outline-btn" onClick={hideDistributorDialog} />
            <Button label="Yes" icon="pi pi-check" className="primary-btn" onClick={deleteSupplier} />
        </>
    );
    const deleteManufacturersDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="outline-btn" onClick={hideDistributorsDialog} />
            <Button label="Yes" icon="pi pi-check" className="primary-btn" onClick={deleteSuppliers} />
        </>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} distributors
                </span>
            );
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2">
                    <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Shops" value={formatNumberWithCommas(totalRecords)} />
                        <StatusSummary title="Male" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                        <StatusSummary title="Female" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                        <StatusSummary title="Total" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
                    </div>
                </div>
                <div className="py-2 -mt-3">
                    <Accordion>
                        <AccordionTab className="p-fluid" header="Filter Distributor">
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasedQuantity">Price range</label>
                                    <InputNumber id="purchasedQuantity" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Amount range</label>
                                    <InputNumber id="purchasePrice" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Batch no</label>
                                    <InputNumber id="purchasePrice" />
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasedQuantity">Purchase date</label>
                                    <InputNumber id="purchasedQuantity" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Purchase by</label>
                                    <InputNumber id="purchasePrice" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Expiry date</label>
                                    <Calendar />
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasedQuantity">Category</label>
                                    <InputNumber id="purchasedQuantity" />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <Button label="Clear" icon="pi pi-times" className="p-button-text " style={{ color: 'black', border: '1px solid black', margin: '5px', width: 'fit-content' }} />
                                <Button label="Filter" icon="pi pi-check" className="p-button-text" style={{ color: '#FFEB3B', border: '1px solid black', backgroundColor: 'black', margin: '5px', width: 'fit-content' }} />
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
                <div className="card">
                    <Toast ref={toast} />

                    <DataTable
                        loading={isLoading}
                        ref={dt}
                        value={distributors}
                        selection={selectedDistributors}
                        onSelectionChange={(e) => setSelectedDistributors(e.value)}
                        dataKey="id"
                        rows={10}
                        className="datatable-responsive"
                        emptyMessage="No distributor found."
                        header={header}
                        size="small"
                        resizableColumns
                    >
                        {/* <Column field="serialNumber" header="Serial number" body={serialNumberBodyTemplate} className="text-center" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'center' }}/> */}
                        <Column field="businessName" header="Company" body={businessNameBodyTemplate} className="text-left" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'left' }} />
                        <Column field="name" header="Contact person" body={contactPersonBodyTemplate} className="text-left" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'left' }} />
                        <Column field="phone" header="Phone" body={phoneBodyTemplate} className="text-center" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'center' }} />
                        <Column field="suppliedShops" header="Supplied Shops" body={totalSuppliedShopsBodyTemplate} className="text-center" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'center' }} />
                        <Column field="createdBy" header="Created by" body={createdByFullNameBodyTemplate} className="text-center" headerStyle={{ width: '20%', minWidth: '8rem', textAlign: 'center' }} />
                        <Column field="dateCreated" header="Created on" body={dateCreatedBodyTemplate} className="text-center" headerStyle={{ width: '15%', minWidth: '10rem', textAlign: 'center' }} />
                        <Column body={actionBodyTemplate} header="Actions" className="text-center" />
                    </DataTable>
                    <Paginator template={template2} first={first} rows={MAXIMUM_RECORDS_PER_PAGE} totalRecords={totalRecords} alwaysShow={false} onPageChange={onPageChange} />

                    <Dialog visible={distributorDialog} style={{ width: '700px' }} header="Distributor" modal className="p-fluid" footer={supplierDialogFoooter} onHide={hideDialog}>
                        <Messages ref={dialogMessage} style={{ width: '100%' }} />
                        <div className="field ">
                            <label htmlFor="companyOrBusinessName">Company name</label>
                            <InputText id="companyOrBusinessName" value={distributor.companyOrBusinessName || ''} onChange={(e) => onInputChange(e, 'companyOrBusinessName')} autoFocus />
                        </div>

                        <h5>Contact person</h5>

                        <div className="formgrid grid" style={{ marginTop: '10px' }}>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="firstName">First Name</label>
                                <InputText id="firstName" value={distributor.firstName || ''} onChange={(e) => onInputChange(e, 'firstName')} required autoFocus className={classNames({ 'p-invalid': submitted && !distributor.firstName })} />
                                {submitted && !distributor.firstName && <small className="p-invalid">First name is required.</small>}
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="lastName">Last Name</label>
                                <InputText id="lastName" value={distributor.lastName || ''} onChange={(e) => onInputChange(e, 'lastName')} required autoFocus className={classNames({ 'p-invalid': submitted && !distributor.lastName })} />
                                {submitted && !distributor.lastName && <small className="p-invalid">Last name is required.</small>}
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="otherName">Other Name</label>
                                <InputText id="otherName" value={distributor.otherName || ''} onChange={(e) => onInputChange(e, 'otherName')} autoFocus />
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col-12 md:col-4">
                                <label htmlFor="phoneNumber">Phone</label>
                                <PhoneInput
                                    enableSearch
                                    id="primaryPhoneNumber"
                                    className={classNames({ 'country-code-reduced-further error-Input': submitted && !distributor.phoneNumber, 'country-code-reduced-further': true })}
                                    value={distributor.phoneNumber || ''}
                                    onChange={(e) => onInputChange(e, 'phoneNumber')}
                                    country={'ug'}
                                    inputProps={{
                                        required: true
                                    }}
                                />
                                {submitted && !distributor.phoneNumber && <small className="p-invalid">Phone is required.</small>}
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="alternativePhoneNumber">Alternative phone number</label>
                                <PhoneInput
                                    enableSearch
                                    id="altPhoneNumber"
                                    className={classNames({ 'country-code-reduced-further': true })}
                                    value={distributor.alternativePhoneNumber || ''}
                                    onChange={(e) => onInputChange(e, 'alternativePhoneNumber')}
                                    country={'ug'}
                                    inputProps={{
                                        required: true
                                    }}
                                />
                            </div>

                            <div className="field col-12 md:col-4">
                                <label htmlFor="email">Email</label>
                                <InputText id="email" value={distributor.email || ''} onChange={(e) => onInputChange(e, 'email')} autoFocus />
                            </div>
                        </div>
                        <div className="field">
                            <label>Supplier</label>
                            <Dropdown value={selectedSupplier} options={suppliers} optionLabel={'companyOrBusinessName'} onChange={(e) => setSelectedSupplier(e.target.value)} placeholder="Select Supplier" maxSelectedLabels={3} />
                        </div>
                        <div className="field">
                            <label htmlFor="remarks">Remarks</label>
                            <InputTextarea
                                style={{ height: 100 }}
                                id="remarks"
                                value={distributor.remarks || ''}
                                onChange={(e) => onInputChange(e, 'remarks')}
                                required
                                autoFocus
                                className={classNames({ 'p-invalid': submitted && !distributor.remarks })}
                            />
                            {submitted && !distributor.remarks && <small className="p-invalid">Remarks is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteDistributorDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteManufacturerDialogFooter} onHide={hideDistributorDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {distributor && (
                                <span>
                                    Are you sure you want to delete <b>{distributor.fullName}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteDistributorsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteManufacturersDialogFooter} onHide={hideDistributorsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {distributor && <span>Are you sure you want to delete the selected distributors?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
export default Distributor;
