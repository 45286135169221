import * as routPaths from './NavigationRoutes';
import AttendantDashboard from '../pages/attendant/AttendantDashboard';
import StockLevel from '../pages/administrator/StockLevel';
import StockEntry from '../pages/administrator/StockEntry';
import ShopSales from '../pages/administrator/ShopSales';
export class ShopAttendantNavigationConfig {
    navigationMenu = [
        {
            items: [{ label: 'Dashboard', icon: 'bx bxs-dashboard', to: routPaths.HOME_ROUTE_PATH }]
        },
        {
            items: [{ label: 'Sales Desk', icon: 'bx bx-receipt', to: routPaths.SHOP_SALES_ROUTE_PATH }]
        },
        {
            items: [{ label: 'Shop Management ', disabled: true }]
        },

        {
            items: [{ label: 'Stock level', icon: 'bx bx-objects-vertical-bottom', to: routPaths.STOCK_LEVELS_ROUTE_PATH }]
        },
        {
            items: [{ label: 'Stock purchase', icon: 'bx bx-layer-plus', to: routPaths.STOCK_ENTRIES_ROUTE_PATH }]
        },
        // {
        //     items: [{ label: 'Money Transfer ', disabled: true }]
        // },
        // {
        //     items: [{ label: 'Mobile Money', icon: 'bx bx-money', to: '/' }]
        // },
        {
            items: [{ label: 'Settings ', disabled: true }]
        },
        {
            items: [{ label: 'Logout', icon: 'bx bx-log-out', to: routPaths.LOGIN_ROUTE_PATH }]
        }
    ];

    componentRouters = [
        { path: routPaths.DASHBOARD_ROUTE_PATH, label: 'Dashboard', component: <AttendantDashboard />, exact: true },
        { path: routPaths.HOME_ROUTE_PATH, label: 'Dashboard', component: <AttendantDashboard />, exact: true },
        { path: routPaths.STOCK_LEVELS_ROUTE_PATH, label: 'StockLevel', component: <StockLevel />, exact: true },
        { path: routPaths.STOCK_ENTRIES_ROUTE_PATH, label: 'StockEntry', component: <StockEntry />, exact: true },
        { path: routPaths.SHOP_SALES_ROUTE_PATH, label: 'Sales Desk', component: <ShopSales />, exact: true }
    ];

    getNavigationMenu() {
        return this.navigationMenu;
    }

    getComponentRouters() {
        return this.componentRouters;
    }
}
