import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import { BaseApiService } from '../../utils/BaseApiService';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { Messages } from 'primereact/messages';
import { formatNumberWithCommas, toReadableDateTime, toReadableTime } from '../../utils/Utils';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import PhoneInput from 'react-phone-input-2';
import StatusSummary from '../../components/StatusSummary';

const User = () => {
    let emptyUser = {
        id: null,
        username: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        emailAddress: null,
        fullName: null,
        genderId: '',
        countryId: '',
        initialPassword: null,
        roles: []
    };

    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [userDialog, setUserDialog] = useState(false);
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [deleteUsersDialog, setDeleteUsersDialog] = useState(false);
    const [user, setUser] = useState(emptyUser);
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [roles, setRoles] = useState(null);
    const [role, setRole] = useState(null);
    const [edit, setEdit] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(null);

    const message = useRef();
    const dialogMessage = useRef();
    const toast = useRef(null);
    const dt = useRef(null);

    const fetchUsers = async () => {
        setIsLoading(true);
        let searchParameters = { offset: 0, limit: 0 };
        new BaseApiService('/users')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setUsers(response.records);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                showErrorMessage(message, error.message);
            });
    };

    const fetchRoles = async () => {
        let searchParameters = { searchTerm: '', offset: 0, limit: 0 };
        new BaseApiService('/users/roles')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setRoles(response.records);
            })
            .catch((error) => {});
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [refresh]);

    const openNew = () => {
        setEdit(false);
        setUser(emptyUser);
        setSelectedRole(null);
        setSubmitted(false);
        setUserDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setUserDialog(false);
    };

    const hideUserDialog = () => {
        setDeleteUserDialog(false);
    };

    const hideDeleteUsersDialog = () => {
        setDeleteUsersDialog(false);
    };

    const saveUser = () => {
        setSubmitted(true);

        const apiUrl = edit && edit === true ? '/users/' + user.id : '/users';

        new BaseApiService(apiUrl)
            .saveRequestWithJsonResponse(
                {
                    username: user.username,
                    roleIds: [selectedRole?.id],
                    emailAddress: user?.emailAddress,
                    lastName: user?.lastName,
                    firstName: user?.firstName,
                    phoneNumber: phoneNumber,
                    initialPassword: user?.initialPassword,
                    genderId: 1
                },
                edit
            )
            .then((response) => {
                if (edit && edit === true) {
                    setRefresh(true);
                } else {
                    let _users = [...users];

                    let _user = { ...response };
                    _users.push(_user);
                    setUsers(_users);
                }
                setUserDialog(false);
                setUser(emptyUser);
                setConfirmPassword(null);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Details saved', life: 3000 });
            })
            .catch((error) => {
                showErrorMessage(dialogMessage, error.message);
            });
    };

    const editUser = (user) => {
        setEdit(true);

        setUser({ ...user });
        setSelectedRole(user.roles[0]);
        setUserDialog(true);
    };

    const comfirmDeleteUser = (user) => {
        setUser(user);
        setDeleteUserDialog(true);
    };

    const deleteUser = () => {
        new BaseApiService('/users/' + user.id)
            .deleteRequestWithJsonResponse()
            .then((response) => {
                let _users = users.filter((val) => val.id !== user.id);
                setUsers(_users);
                setDeleteUserDialog(false);
                setUser(emptyUser);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Deleted', life: 3000 });
            })
            .catch((error) => {});
    };

    const confirmDeleteSelected = () => {
        setDeleteUsersDialog(true);
    };

    const deleteSelectedUsers = () => {
        let _users = users.filter((val) => !selectedUsers.includes(val));
        setUsers(_users);
        setDeleteUsersDialog(false);
        setSelectedUsers(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Deleted', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = { ...user };
        _user[`${name}`] = val;

        setUser(_user);
    };

    const onRoleChange = (e) => {
        setSelectedRole(e.target.value);
    };

    const usernameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Username</span>
                {rowData.username}
            </>
        );
    };

    const roleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Role</span>
                {rowData.roles && rowData.roles.length > 0 ? rowData.roles[0].name : ''}
            </>
        );
    };

    const fullNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Last name</span>
                {rowData.firstName + ' ' + rowData.lastName}
            </>
        );
    };

    const emailAddressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email Address</span>
                {rowData.emailAddress}
            </>
        );
    };

    const phoneNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Phone number</span>
                {rowData.phoneNumber}
            </>
        );
    };

    const createdByFullNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created by</span>
                {rowData.createdByFullName}
            </>
        );
    };

    const dateCreatedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date Created</span>
                {toReadableDateTime(rowData.dateCreated)}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex md:flex-row md:align-items-center">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success mr-2" onClick={() => editUser(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger mt-2" onClick={() => comfirmDeleteUser(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0">Users</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setGlobalFilter(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" />
                </div>
            </span>
            <div className="my-2 ml-auto">
                <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
                <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} style={selectedUsers && selectedUsers.length > 1 ? { display: 'inline-flex' } : { display: 'none' }} />
            </div>
        </div>
    );

    const userDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className=" outline-btn" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className=" primary-btn" onClick={saveUser} />
        </>
    );
    const deleteUserDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideUserDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteUser} />
        </>
    );
    const deleteUsersDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUsersDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedUsers} />
        </>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} users
                </span>
            );
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2">
                    <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Total" value={formatNumberWithCommas(users.length)} />
                        <StatusSummary title="Male" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                        <StatusSummary title="Female" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                        <StatusSummary title="Total" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
                    </div>
                </div>

                <div className="card">
                    <Toast ref={toast} />

                    <DataTable
                        loading={isLoading}
                        ref={dt}
                        value={users}
                        selection={selectedUsers}
                        onSelectionChange={(e) => setSelectedUsers(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        className="datatable-responsive"
                        paginatorTemplate={template2}
                        globalFilter={globalFilter}
                        emptyMessage="No users found."
                        header={header}
                    >
                        <Column field="username" header="Username" body={usernameBodyTemplate} />
                        <Column field="fName" header="Full name" body={fullNameBodyTemplate} />
                        <Column field="emailAddress" header="Email address" body={emailAddressBodyTemplate} />
                        <Column field="phoneNumber" header="Phone number" body={phoneNumberBodyTemplate} />
                        <Column field="role" header="Role" body={roleBodyTemplate} />
                        <Column field="createdBy" header="Created by" body={createdByFullNameBodyTemplate} />
                        <Column field="dateCreated" header="Created on" body={dateCreatedBodyTemplate} />
                        <Column body={actionBodyTemplate} header="Actions" />
                    </DataTable>

                    <Dialog visible={userDialog} style={{ width: '700px' }} header="User Details" modal className="p-fluid" footer={userDialogFooter} onHide={hideDialog}>
                        <div className="formgrid grid">
                            <Messages ref={dialogMessage} style={{ width: '100%' }} />

                            <div className="field col">
                                <label htmlFor="firstName">First Name</label>
                                <InputText id="firstName" value={user.firstName || ''} onChange={(e) => onInputChange(e, 'firstName')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.firstName })} />
                                {submitted && !user.firstName && <small className="p-invalid">First name is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="lastName">Last Name</label>
                                <InputText id="lastName" value={user.lastName || ''} onChange={(e) => onInputChange(e, 'lastName')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.lastName })} />
                                {submitted && !user.lastName && <small className="p-invalid">Last name is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="username">Username</label>
                                <InputText id="username" value={user.username || ''} onChange={(e) => onInputChange(e, 'username')} autoFocus className={classNames({ 'p-invalid': submitted && !user.username })} />
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="emailAddress">Email Address</label>
                                <InputText id="emailAddress" value={user.emailAddress || ''} onChange={(e) => onInputChange(e, 'emailAddress')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.emailAddress })} />
                                {submitted && !user.emailAddress && <small className="p-invalid">Email address is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="phoneNumber">Phone number</label>
                                <PhoneInput
                                    id="primaryPhoneNumber"
                                    className={classNames({ 'country-code-reduced-further error-Input': submitted && !phoneNumber, 'country-code-reduced-further': true })}
                                    value={phoneNumber || ''}
                                    onChange={(e) => setPhoneNumber(e)}
                                    country={'ug'}
                                    enableSearch
                                    inputProps={{
                                        required: true
                                    }}
                                />
                                {submitted && !user.phoneNumber && <small className="p-invalid">Phone number is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="role">Role</label>
                                <Dropdown id="role" value={selectedRole} options={roles} onChange={onRoleChange} optionLabel="name" placeholder="Select a Role" />
                            </div>
                        </div>

                        <hr />

                        <div className="formgrid grid" style={edit === true ? { display: 'none' } : { display: '' }}>
                            <div className="field col">
                                <label>Set Password</label>
                            </div>
                            <div className="field col">
                                <label htmlFor="password">Password</label>
                                <InputText id="password" value={user.initialPassword || ''} onChange={(e) => onInputChange(e, 'initialPassword')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.initialPassword })} />
                                {submitted && !user.initialPassword && <small className="p-invalid">Password is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="confirmPassword">Confirm Password</label>
                                <InputText id="confirmPassword" value={confirmPassword || ''} onChange={(e) => setConfirmPassword(e.target.value)} required autoFocus className={classNames({ 'p-invalid': submitted && !confirmPassword })} />
                                {submitted && !confirmPassword && <small className="p-invalid">Confirm password is required.</small>}
                                {submitted && user.initialPassword && confirmPassword && user.initialPassword !== confirmPassword && <small className="p-invalid">Confirm password doesn't match password.</small>}
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteUserDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteUserDialogFooter} onHide={hideUserDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {user && (
                                <span>
                                    Are you sure you want to delete <b>{user.username}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteUsersDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteUsersDialogFooter} onHide={hideDeleteUsersDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {user && <span>Are you sure you want to delete the selected users?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default User;
