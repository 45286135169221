export const HOME_ROUTE_PATH = '/';
export const LOGIN_ROUTE_PATH = '/login';
export const DASHBOARD_ROUTE_PATH = '/dashboard';
export const MANUFACTURERS_ROUTE_PATH = '/manufacturers';
export const SUPPLIERS_ROUTE_PATH = '/suppliers';
export const DISTRIBUTORS_ROUTE_PATH = '/distributors';
export const STOCK_LEVELS_ROUTE_PATH = '/stock-levels';
export const STOCK_ENTRIES_ROUTE_PATH = '/stock-entries';
export const PRODUCTS_ROUTE_PATH = '/products';
export const LOOKUPS_ROUTE_PATH = '/lookupvalues';
export const CURRENCIES_ROUTE_PATH = '/currencies';
export const SHOP_OWNERS_ROUTE_PATH = '/shopOwners';
export const SHOPS_ROUTE_PATH = '/shops';
export const SHOP_ATTENDANTS_ROUTE_PATH = '/attendants';
export const SHOP_CAPITAL_ROUTE_PATH = '/capital';
export const USERS_ROUTE_PATH = '/users';
export const ROLES_ROUTE_PATH = '/roles';
export const SHOP_SALES_ROUTE_PATH = '/sales';
export const STOCK_LISTING_ROUTE_PATH = '/stock-listing';
export const EXPENSES_ROUTE_PATH = '/expenses';
export const REPORTS_ROUTE_PATH = '/reports';
export const FINANCIAL_SUMMARY_PATH = '/financialSummary';
export const SIGN_UP_ROUTE_PATH = '/signup';
export const SHOP_DETAILS_ROUTE_PATH = '/shop/:shopId';
export const NEW_PRODUCT = '/new_product';
export const PERMISSIONS = '/permissions';
export const CREDIT_SALES_ROUTE_PATH = '/credit-sales';
export const CREDIT_PAYMENTS_ROUTE_PATH = '/credit-payments';
export const SHOP_CLIENTS = '/shop-clients';
export const SHOP_REPORTS_ROUTE_PATH = '/reports/:shopName';
export const SHOP_STOCK_LEVELS_ROUTE_PATH = '/stock-levels/:shopName';
