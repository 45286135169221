import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import { BaseApiService } from '../../utils/BaseApiService';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Messages } from 'primereact/messages';
import { NumericFormat } from 'react-number-format';
import { formatNumberWithCommas, toReadableDateTime } from '../../utils/Utils';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import { Calendar } from 'primereact/calendar';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { TabView, TabPanel } from 'primereact/tabview';
import StatusSummary from '../../components/StatusSummary';

const ShopCapital = () => {
    let initialCapital = {
        id: null,
        note: null,
        amount: null,
        typeName: null,
        typeId: null,
        shopName: null,
        shopId: null
    };

    const [capitals, setCapitals] = useState(null);
    const [capitalDialog, setCapitalDialog] = useState(false);
    const [deleteCapitalDialog, setDeleteCapitalDialog] = useState(false);
    const [deleteCapitalsDialog, setDeleteCapitalsDialog] = useState(false);
    const [capital, setCapital] = useState(initialCapital);
    const [selectedCapitals, setSelectedCapitals] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedType, setSelectedType] = useState({
        typeId: null,
        typeName: null
    });
    const [edit, setEdit] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [selectedShop, setSelectedShop] = useState(null);
    const [shops, setShops] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const types = [
        { typeId: '1', typeName: 'ADDITION' },
        { typeId: '2', typeName: 'SUBTRACTION' }
    ];

    const dialogMessage = useRef();
    const toast = useRef(null);
    const dt = useRef(null);

    const fetchShops = async () => {
        let searchParameters = { searchTerm: '', offset: 0, limit: 0 };
        if (UserSessionUtils.getShopOwner()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }
        new BaseApiService('/shops')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                if (response.records.length > 1) {
                    setShops([{ name: 'All shops', id: UserSessionUtils.getShopOwnerId() }, ...response?.records.reverse()]);
                    return;
                }
                setShops(response.records);
            })
            .catch((error) => {});
    };

    const fetchCapitals = async () => {
        let searchParameters = { searchTerm: '', offset: 0, limit: 0, ...(activeIndex > 0 && { shopId: shops[activeIndex]?.id }) };

        if (UserSessionUtils.getShopOwner()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }
        new BaseApiService('/shops/capital')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setCapitals(response);
            })
            .catch((error) => {});
    };

    const deleteCapital = () => {
        // new BaseApiService("/shop/owners/" + shop.id).deleteRequestWithJsonResponse().then((response) => {
        //
        let _capitals = capitals.filter((val) => val.id !== capital.id);
        setCapitals(_capitals);
        setDeleteCapitalDialog(false);
        setCapital(initialCapital);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Capital entry Deleted', life: 3000 });
        // }).catch((error) => {
        //
        // })
    };

    const deleteSelectedCapitals = () => {
        let _capitals = capitals.filter((val) => !selectedCapitals.includes(val));
        setCapitals(_capitals);
        setDeleteCapitalDialog(false);
        setSelectedCapitals(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Capital entries Deleted', life: 3000 });
    };

    useEffect(() => {
        fetchShops();
    }, []);

    useEffect(() => {
        fetchCapitals();
    }, [refresh, activeIndex]);

    const openNew = () => {
        setCapital(initialCapital);
        setSubmitted(false);
        setCapitalDialog(true);
        setEdit(false);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setCapitalDialog(false);
    };

    const saveCapital = () => {
        setSubmitted(true);

        const apiUrl = edit && edit === true ? '/shops/capital/' + capital.id : '/shops/capital';

        new BaseApiService(apiUrl)
            .saveRequestWithJsonResponse(
                {
                    amount: capital.amount,
                    note: capital.note,
                    typeId: selectedType.typeId,
                    shopId: selectedShop.id
                },
                edit
            )
            .then((response) => {
                if (edit && edit === true) {
                    setRefresh(true);
                } else {
                    let _capitals = [...capitals];

                    let _capital = { ...response };

                    _capitals.push(_capital);

                    setCapitals(_capitals);
                }
                setCapitalDialog(false);
                setCapital(initialCapital);
                setSelectedType(null);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Shop capital Details saved', life: 3000 });
            })
            .catch((error) => {});
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _capital = { ...capital };
        _capital[`${name}`] = val;

        setCapital(_capital);
    };

    const onTypeChange = (e) => {
        setSelectedType(e.target.value);
    };

    const numberFormat = (value) => <NumericFormat value={value} displayType={'text'} thousandSeparator={true} />;

    const amountBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Amount</span>
                {numberFormat(rowData.amount)}
            </>
        );
    };

    const typeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type</span>
                {rowData.typeName.toLowerCase().replace(/(^|\s)\S/g, (L) => L.toUpperCase())}
            </>
        );
    };

    const noteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Note</span>
                {rowData.note}
            </>
        );
    };

    const dateCreatedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date Created</span>
                {toReadableDateTime(rowData.dateCreated)}
            </>
        );
    };

    const shopBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Shop name</span>
                {rowData.shopName}
            </>
        );
    };

    const createdByFullNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created by</span>
                {rowData.createdByFullName}
            </>
        );
    };

    const onShopChange = (e) => {
        setSelectedShop(e.target.value);
    };

    const editCapital = (capital) => {
        setEdit(true);

        setCapital({ ...capital });
        setSelectedShop(shops.find((obj) => obj.id === capital.shopId));
        setSelectedType(types.find((obj) => obj.id === capital.typeId));
        setCapitalDialog(true);
    };

    const confirmDeleteCapital = (capital) => {
        setCapital(capital);
        setDeleteCapitalDialog(true);
    };

    const hideCapitalDialog = () => {
        setDeleteCapitalDialog(false);
    };

    const hideDeleteCapitalsDialog = () => {
        setDeleteCapitalsDialog(false);
    };
    const confirmDeleteSelected = () => {
        setDeleteCapitalsDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex md:flex-row md:align-items-center justify-content-center">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success mr-2" onClick={() => editCapital(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger mr-2" onClick={() => confirmDeleteCapital(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0">Capital</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setGlobalFilter(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" />
                </div>
            </span>
            <div className="my-2 ml-auto">
                <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
                <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} style={selectedCapitals && selectedCapitals.length > 1 ? { display: 'inline-flex' } : { display: 'none' }} />
            </div>
        </div>
    );

    const capitalDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="outline-btn" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="primary-btn" onClick={saveCapital} />
        </>
    );

    const deleteCapitalDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideCapitalDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteCapital} />
        </>
    );

    const deleteCapitalsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCapitalsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCapitals} />
        </>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} capital entries
                </span>
            );
        }
    };

    const dynamicTabs = () => {
        if (shops?.length > 1 && !UserSessionUtils.getSuperAdmin()) {
            return (
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className='h-2rem'>
                    {shops.map((shop, index) => (
                        <TabPanel key={index} header={shop.name} className="h-auto"></TabPanel>
                    ))}
                </TabView>
            );
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2">
                    <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Shops" value={formatNumberWithCommas(0)} />
                        <StatusSummary title="Additions" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                        <StatusSummary title="Subtractions" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                        <StatusSummary title="Capital" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
                    </div>
                </div>
                <div className="py-2 -mt-3">
                    <Accordion>
                        <AccordionTab className="p-fluid" header="Filter capital">
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasedQuantity">Price range</label>
                                    <InputNumber id="purchasedQuantity" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Amount range</label>
                                    <InputNumber id="purchasePrice" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Batch no</label>
                                    <InputNumber id="purchasePrice" />
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasedQuantity">Purchase date</label>
                                    <InputNumber id="purchasedQuantity" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Purchase by</label>
                                    <InputNumber id="purchasePrice" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Expiry date</label>
                                    <Calendar />
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasedQuantity">Category</label>
                                    <InputNumber id="purchasedQuantity" />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <Button label="Clear" icon="pi pi-times" className="p-button-text " style={{ color: 'black', border: '1px solid black', margin: '5px', width: 'fit-content' }} />
                                <Button label="Filter" icon="pi pi-check" className="p-button-text" style={{ color: '#FFEB3B', border: '1px solid black', backgroundColor: 'black', margin: '5px', width: 'fit-content' }} />
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
                <div className="card">
                    <Toast ref={toast} />

                    {header}

                    <DataTable
                        ref={dt}
                        value={capitals}
                        selection={selectedCapitals}
                        onSelectionChange={(e) => setSelectedCapitals(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        className="datatable-responsive"
                        paginatorTemplate={template2}
                        globalFilter={globalFilter}
                        emptyMessage="No capital history found."
                        header={dynamicTabs}
                    >
                        <Column field="shop" header="Shop" body={shopBodyTemplate} />
                        <Column field="amount" header="Amount" className="text-center" headerStyle={{ textAlign: 'center' }} body={amountBodyTemplate} />
                        <Column field="type" header="Type" body={typeBodyTemplate} className="text-center" headerStyle={{ textAlign: 'center' }} />
                        <Column field="note" header="Notes" body={noteBodyTemplate} />
                        <Column field="createdBy" header="Created by" body={createdByFullNameBodyTemplate} />
                        <Column field="dateCreated" header="Created on" body={dateCreatedBodyTemplate} />
                        <Column body={actionBodyTemplate} header="Actions" className="text-center" headerStyle={{ textAlign: 'center' }} />
                    </DataTable>

                    <Dialog visible={capitalDialog} style={{ width: '700px' }} header="Capital Details" modal className="p-fluid" footer={capitalDialogFooter} onHide={hideDialog}>
                        <Messages ref={dialogMessage} style={{ width: '100%' }} />
                        <div className="field" style={{ marginTop: '10px' }}>
                            <label htmlFor="shop">Shop</label>
                            <Dropdown id="shop" value={selectedShop} options={shops} onChange={onShopChange} optionLabel={'name'} filter showClear filterBy="name" placeholder="Select a Shop" />
                            {submitted && !selectedShop && <small className="p-invalid">Shop is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="type">Type</label>
                            <Dropdown id="type" value={selectedType} options={types} onChange={onTypeChange} optionLabel="typeName" placeholder="Select Type" />
                        </div>
                        <div className="field">
                            <label htmlFor="amount">Amount</label>
                            <InputNumber id="amount" value={capital.amount || ''} onValueChange={(e) => onInputChange(e, 'amount')} required autoFocus className={classNames({ 'p-invalid': submitted && !capital.amount })} />
                            {submitted && !capital.amount && <small className="p-invalid">Amount is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="note">Note</label>
                            <InputTextarea id="note" value={capital.note || ''} onChange={(e) => onInputChange(e, 'note')} required autoFocus className={classNames({ 'p-invalid': submitted && !capital.note })} />
                            {submitted && !capital.note && <small className="p-invalid">Note is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCapitalDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCapitalDialogFooter} onHide={hideCapitalDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {capital && <span>Are you sure you want to delete this capital entry?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCapitalsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCapitalsDialogFooter} onHide={hideDeleteCapitalsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {capital && <span>Are you sure you want to delete the selected capital entries?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default ShopCapital;
